import React from "react";
import navigationConfig from "../../routes/navigationConfig";
import { NavBar } from "../navBar";
import { Footer } from "../footer";


interface UserLayoutProps {
    children: React.ReactNode;
}

const UserLayout: React.FC<UserLayoutProps> = ({ children }) => {
    return (
        <div>
            <NavBar navigation={navigationConfig} />
            <div className="pt-[calc(68px+5rem)]">
                {children}
            </div>
            <Footer />
        </div>
    );
}

export default UserLayout;
