import React, { useEffect } from "react";
import { CommonHeader } from "../../../../components/CommonHeader";
import { Link, useLocation } from "react-router-dom";

const data = [
    {
        title: "Language Courses",
        description: "For Students JLPT / NAT",
        icon: "assets/images/icons/icon-2.png",
        scrollSection: "for-students"
    },
    // {
    //     title: "Language Courses",
    //     description: "For Business Purposes",
    //     icon: "assets/images/icons/icon-1.png",
    //     scrollSection: "for-business"
    // },
    // {
    //     title: "JLPT / NAT",
    //     description: "Past Paper Discussions",
    //     icon: "assets/images/icons/icon-3.png",
    //     scrollSection: "past-paper"
    // },
    {
        title: "Masters",
        description: "English Medium Masters",
        icon: "assets/images/icons/icon-3.png",
        scrollSection: "masters"
    }

]

const JapaneseLanguage: React.FC = () => {
    const location = useLocation();

    const handleScroll = (id: string) => {
        // const element = document.getElementById(id);
        // if (element) {
        //     element.scrollIntoView({ behavior: "smooth" });
        // }

        // with padding top of 48px
        const element = document.getElementById(id);
        const offset = 148;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element?.getBoundingClientRect().top;
        const elementPosition = elementRect ? elementRect - bodyRect : 0;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        if (!location?.state) {
            scrollTo(0, 0);
        } else {
            const id = location?.state?.scrollSection;
            handleScroll(id);
        }
    }, []);
    return (
        <div className="min-h-[calc(100vh-48px-3.85rem)]" >
            <CommonHeader title='JAPANESE LANGUAGE CLASSES' bgImage='bg-Service-1' />

            <div className="bg-white">
                <div className="container py-10 flex justify-center flex-col items-center space-y-4">
                    <div className='parallelogram' />
                    <p className="text-[20px] font-railway">HOW CAN WE HELP YOU?</p>
                    <div className="flex items-center md:gap-3 text-[28px] md:text-[40px] font-bold flex-wrap">
                        <span className="text-[#525967]"> LANGUAGE COURSES</span>
                        <span className="text-primaryColor"> OVERVIEW </span>
                    </div>
                    <div className="w-[75%] text-center">We provide Japanese Language Courses For Students Or For People Who Are Intending To Do Business.
                        We Also Conduct JLPT And NAT Past Paper Classes As Well.
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-14 md:gap-6 px-4 uppercase py-10">
                        {data.length > 0 && data.map((item, index) => (
                            <div key={index}>
                                <div className="group flex flex-col items-center justify-center relative cursor-pointer space-y-4" onClick={() => handleScroll(item.scrollSection)}>

                                    <div className="bg-primaryColor p-4  transition-all duration-300 flip-card"
                                        onClick={() => handleScroll(item.scrollSection)}

                                    >
                                        <img
                                            className="w-[60px]"
                                            src={item.icon}
                                            alt="Workflow"
                                        />
                                    </div>
                                    <h2 className="text-2xl font-bold text-center">{item.title}</h2>
                                    <div className="bg-primaryColor w-20 h-1 mx-auto transition-all duration-300 group-hover:w-36" />
                                    <p className="text-center">{item.description}</p>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <div className='flex flex-col justify-center'>
                    <div className='bg-cover bg-top h-full fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-Service-7' />
                    <div className="container z-10">
                        <div className="px-5 md:px-20 pt-16 pb-8 text-white text-center">
                            <div className="text-[20px] md:text-[28px]"> WE PROVIDE YOU WITH PROFFESIONAL</div>

                            <div className="text-[28px] md:text-[48px] font-bold">TEACHERS</div>
                        </div>
                    </div>
                    <div className="w-full bg-gray-100  mt-36 pb-20">
                        <div className="container z-10  -mt-36">
                            <div className="shadow-xl">
                                <section id="for-students">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">

                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                LANGUAGE COURSES
                                            </span>

                                            <span className="text-[20px] md:text-[28px] font-thin">
                                                FOR STUDENTS
                                                <br />
                                                JLPT / NAT</span>
                                        </div>
                                        <div className="bg-white flex flex-col items-center justify-center text-black space-y-10 p-5 border-b-[8px] border-primaryColor">
                                            <span className="text-justify font-bold text-[0.9rem]">
                                                The Japanese-Language Proficiency Test (日本語能力試験) or
                                                JLPT, is a standardized test to evaluate and certify
                                                Japanese language proficiency. This covers language
                                                knowledge, reading ability, and listening ability. JLPT
                                                tests consists of five levels (N1 – N5), N1 being highest
                                                certification. One of the advantages of this certification
                                                is that this does not expire.
                                            </span>
                                            <span className="text-center font-bold text-[0.95rem] w-[90%]">
                                                JLPT N5/ NAT N5 is required for foreign students who wish to study at Japanese schools or universities.
                                            </span>
                                            <div className="flex justify-center flex-col items-center gap-8">
                                                <div className="flex items-center">
                                                    <Link
                                                        to="/japanese-language-classes/full-time-course"
                                                        className="bg-primaryColor uppercase text-white rounded-bl-2xl py-[0.65rem] pl-6 pr-6 -mr-2 hover:bg-black ease-in-out transition-all duration-300 cursor-pointer"
                                                    >
                                                        Full time
                                                    </Link>
                                                    <span className="p-4 bg-primaryColorDark uppercase text-white rounded-tr-2xl rounded-bl-2xl z-10">OR</span>
                                                    <Link
                                                        to="/japanese-language-classes/part-time-course"
                                                        className="bg-primaryColor uppercase text-white rounded-tr-2xl py-[0.65rem] pr-6 pl-6 -ml-2 hover:bg-black ease-in-out transition-all duration-300 cursor-pointer"
                                                    >
                                                        Part time
                                                    </Link>
                                                </div>
                                                <div className="flex items-center">
                                                    <Link
                                                        to="/japanese-language-classes/advance-course"
                                                        className="py-[0.65rem] px-6 bg-primaryColor uppercase text-white rounded-tl-2xl rounded-br-2xl z-10 hover:bg-black ease-in-out transition-all duration-300 cursor-pointer"
                                                    >
                                                        Advance Course
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="masters">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">
                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                MASTER&apos;S
                                            </span>

                                            <span className="text-[20px] md:text-[28px] font-thin">
                                                ENGLISH MEDIUM
                                                <br />
                                                MASTERS</span>
                                        </div>
                                        <div className="bg-white flex flex-col justify-center text-black space-y-10 p-5 border-b-[8px] border-primaryColor">
                                            <span className="text-justify font-bold text-[0.9rem]">
                                                We provide English medium master’s program in Japan. This program is designed for students who wish to pursue their higher education in Japan. The program is designed to provide students with the necessary skills and knowledge to succeed in their chosen field of study.
                                            </span>
                                            <div className="text-[0.95rem] text-justify pl-4 md:pl-8 font-bold space-y-2">
                                                <span className="-ml-4 pr-2">#</span>
                                                <span className="uppercase">
                                                    Field of Study
                                                </span>
                                                <ul className="text-left space-y-2 list-disc pl-5 md:pl-10">
                                                    <li> 1. Business Data Analytics </li>
                                                    <li> 2. IT Technology  </li>
                                                    <li> 3.Enterprises Resource Planning (ERP) </li>
                                                    <li> 4. IT Anime and Manga </li>
                                                    <li> 5. Global Entrepreneurship </li>
                                                    <li> 6. Network Administration </li>
                                                    <li> 7. Web Systems Development </li>
                                                </ul>
                                            </div>
                                            <div className="text-[0.95rem] text-justify pl-4 md:pl-8 font-bold space-y-2">
                                                <span className="-ml-4 pr-2">#</span>
                                                <span className="uppercase">
                                                    ELIGIBILITY CRITERIA
                                                </span>
                                                <ul className="text-left space-y-2 list-disc pl-5 md:pl-10">
                                                    <li> Bachelor&apos;s degree in any field </li>
                                                    <li> IELTS not compulsory </li>
                                                    <li> Japanese not compulsory </li>
                                                    <li> Age limit: below 30 years </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="for-business">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">

                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                LANGUAGE COURSES
                                            </span>

                                            <span className="text-[20px] md:text-[28px] font-thin">
                                                FOR BUSINESS PURPOSE
                                            </span>
                                        </div>
                                        <div className="bg-white flex flex-col items-center justify-center text-black space-y-10 p-5 border-b-[8px] border-primaryColor">
                                            <ul className=" text-justify space-y-5 text-[0.90rem] font-bold">
                                                <li>
                                                    Applicant with JLPT N1 receive 15 points and JLPT N2 receive 10 points under the government’s Point-based Preferential Immigration Treatment System for Highly Skilled Foreign Professionals.
                                                </li>
                                                <li>
                                                    Medical professionals who wish to take examinations to be licensed in Japan must have JLPT N1.
                                                </li>
                                                <li>
                                                    Applicant with N1 or N2 are exempt from the Japanese language section of the middle school equivalency examination.
                                                </li>
                                                <li>
                                                    JLPT N3 or higher certification is required to nurse or caregiver candidates under the Economic Partnership Agreement to work in japan.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                                <section id="past-paper">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">

                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                JAPANESE LANGUAGE PROFICIENCY
                                                TEST
                                            </span>

                                            <span className="text-[20px] md:text-[28px] font-thin">
                                                AST PAPER DICUSSION
                                            </span>
                                        </div>
                                        <div className="bg-white flex flex-col items-center justify-center text-black space-y-10 p-5">
                                            <span className="text-[0.9rem] text-justify font-bold">
                                                The class will be focused on the examination, in order to achieve that constant reference and discussion is required. Each level certified candidate will be accepted to be qualified as follows
                                            </span>
                                            <div className="text-[0.95rem] text-justify pl-4 md:pl-8 font-bold space-y-4">
                                                <span className="-ml-4 pr-2">#</span>
                                                <span>
                                                    N5 – Language Knowledge (Vocabulary/Grammar) – The ability to understand Japanese used in a variety of circumstances in Basic Level.
                                                </span>
                                                <ul className="text-left space-y-5 list-disc pl-5 md:pl-10">
                                                    <li>
                                                        Reading – The ability to understand, read and write expressions and sentences.
                                                    </li>
                                                    <li>
                                                        Listening – The ability to listen, understand and speak on familiar daily topics.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="text-[0.95rem] text-justify pl-4 md:pl-8 font-bold space-y-4">
                                                <span className="-ml-4 pr-2">#</span>
                                                <span>
                                                    N4 – Language Knowledge (Vocabulary/Grammar) – The ability to understand Japanese used in a variety of circumstances in Elementary Level.
                                                </span>
                                                <ul className="text-left space-y-5 list-disc pl-5 md:pl-10">
                                                    <li>
                                                        Reading – The ability to understand, read and write passages on familiar daily topics.
                                                    </li>
                                                    <li>
                                                        Listening – The ability to listen, understand and
                                                    </li>
                                                    <li>
                                                        speak on familiar daily topics.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="text-[0.95rem] text-justify pl-4 md:pl-8 font-bold space-y-4">
                                                <span className="-ml-4 pr-2">#</span>
                                                <span>
                                                    N3 – Language Knowledge (Vocabulary/Grammar) – The ability to understand Japanese used in a variety of circumstances in Intermediate Level.
                                                </span>
                                                <ul className="text-left space-y-5 list-disc pl-5 md:pl-10">
                                                    <li>
                                                        Reading – The ability to read and write with logical complexity on a variety of topics and understand the profound contents on various topics to narrate the intent of the writers comprehensively.
                                                    </li>
                                                    <li>
                                                        Listening – The ability to listen and comprehend coherent conversations in everyday situations.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="text-[0.95rem] text-justify pl-4 md:pl-8 font-bold space-y-4">
                                                <span className="-ml-4 pr-2">#</span>
                                                <span>
                                                    N2 – Language Knowledge (Vocabulary/Grammar) – The ability to understand Japanese used in a variety of circumstances in pre-advanced level
                                                </span>
                                                <ul className="text-left space-y-5 list-disc pl-5 md:pl-10">
                                                    <li>
                                                        Reading – The ability to read and write with on materials with specific contents concerning everyday topics.
                                                    </li>
                                                    <li>
                                                        Listening The ability to understand conversations with their indented purpose and orally present materials such as coherent conversations.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="text-[0.95rem] text-justify pl-4 md:pl-8 font-bold space-y-4">
                                                <span className="-ml-4 pr-2">#</span>
                                                <span>
                                                    N1 – Language Knowledge (Vocabulary/Grammar) – The ability to understand Japanese used in a variety of circumstances in advanced level.
                                                </span>
                                                <ul className="text-left space-y-5 list-disc pl-5 md:pl-10">
                                                    <li>
                                                        Reading – The ability to read write with logical complexity on a variety of topics and understand the profound contents on various topics to narrate the intent of the writers comprehensively.
                                                    </li>
                                                    <li>
                                                        Listening The ability to understand logical complexity topics and their indented purpose and orally present materials such as coherent conversations.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JapaneseLanguage;
