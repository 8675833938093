import React, { useEffect } from 'react';
import HeroSection from './widgets/HeroSection';
import ServiceSection from './widgets/ServiceSection';
import Consultations from './widgets/Consultations';
import OverVision from './widgets/OverVision';

const Home: React.FC = () => {
    useEffect(() => {
        scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeroSection />
            <ServiceSection />
            <Consultations />
            <OverVision />
        </>
    );
};

export default Home;
