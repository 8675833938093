import React from 'react';
import { FormProps } from '../../../../models/form.interface';
import InputField from '../../../components/form-fields/inputField';

interface StudyTypeProps {
    setForm: React.Dispatch<React.SetStateAction<FormProps>>;
    form: FormProps;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, id?: string) => void;
    error: FormProps;
}

const FamilyInfo: React.FC<StudyTypeProps> = ({ form, handleInputChange, setForm, error }) => {
    const addFamilyRecord = () => {
        setForm(prevForm => ({
            ...prevForm,
            familyInfo: [
                ...prevForm.familyInfo,
                {
                    familyId: String(form.academicRecord).length === 0 ? '1' : String(form.academicRecord.length + 1),
                    relation: '',
                    relativeName: '',
                    relativeOccupation: '',
                    relativeDateOfBirth: '',
                },
            ],
        }));
    };

    const deleteFamilyRecord = (index: number) => {
        // setForm 
        setForm(prevForm => ({
            ...prevForm,
            familyInfo: [
                ...prevForm.familyInfo.slice(0, index),
                ...prevForm.familyInfo.slice(index + 1),
            ],
        }));

    };

    return (
        <div className='space-y-5'>
            {form.familyInfo.map((family, index) => (
                <div key={index} className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                    <div className="flex flex-col w-full gap-2 col-span-2 md:col-span-1">
                        <label htmlFor={`relation${index}`} className="text-primaryColor font-normal">
                            Relation <span className='text-[10px]'>ex: Father, Mother, Brother, Sister, etc.</span>
                        </label>
                        <InputField
                            type='text'
                            id={`relation${index}`}
                            name={`relation${index}`}
                            placeholder='Relation'
                            value={family.relation}
                            onChange={(e) => handleInputChange(e, `familyInfo.${index}.relation`)}
                            error={error.familyInfo && error.familyInfo[index] && error.familyInfo[index].relation}
                        />

                    </div>
                    <div className='col-span-2 md:col-span-1'>
                        <InputField
                            label='Relative Name'
                            type='text'
                            id={`relativeName${index}`}
                            name={`relativeName${index}`}
                            placeholder='Relative Name'
                            value={family.relativeName}
                            onChange={(e) => handleInputChange(e, `familyInfo.${index}.relativeName`)}
                            error={error.familyInfo && error.familyInfo[index] && error.familyInfo[index].relativeName}
                        />
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                        <InputField
                            label='Relative Occupation'
                            type='text'
                            id={`relativeOccupation${index}`}
                            name={`relativeOccupation${index}`}
                            placeholder='Relative Occupation'
                            value={family.relativeOccupation}
                            onChange={(e) => handleInputChange(e, `familyInfo.${index}.relativeOccupation`)}
                            error={error.familyInfo && error.familyInfo[index] && error.familyInfo[index].relativeOccupation}
                        />
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                        <InputField
                            label='Relative Date of Birth'
                            type='date'
                            id={`relativeDateOfBirth${index}`}
                            name={`relativeDateOfBirth${index}`}
                            placeholder='Relative Date of Birth'
                            value={family.relativeDateOfBirth}
                            onChange={(e) => handleInputChange(e, `familyInfo.${index}.relativeDateOfBirth`)}
                            error={error.familyInfo && error.familyInfo[index] && error.familyInfo[index].relativeDateOfBirth}
                        />
                    </div>


                    {index !== 0 &&
                        <button
                            className='col-span-2 w-full px-4 py-2 bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out'
                            onClick={() => deleteFamilyRecord(index)}
                        >
                            Remove
                        </button>
                    }
                    <div className='h-0.5 bg-gray-300 w-full col-span-2' />
                </div>
            ))}
            <button
                className='px-4 py-2 w-full bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out'
                onClick={addFamilyRecord}
            >
                Add New Family Record
            </button>
        </div>
    );
};

export default FamilyInfo;
