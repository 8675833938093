import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StudyType from './studyType';
import { FormProps } from '../../../../models/form.interface';
import PersonalInfo from './personalInfo';
import AcademicRecord from './academicRecord';
import FamilyInfo from './familyInfo';
import { CustomError } from '../../../../models/global.interface';
import { toast } from 'react-toastify';
import { POST } from '../../../auth/AxiosHelper';

const steps = [
    'Study Type',
    'Personal Information',
    'Academic record',
    'Family Information',
];

const Form: React.FC = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [form, setForm] = React.useState<FormProps>({
        studyType: '',
        personalInfo: {
            studentNumber: '',
            nameWithInitials: '',
            fullName: '',
            dateOfBirth: '',
            age: '',
            gender: '',
            nic: '',
            passportNumber: '',
            passportExpiryDate: '',
            nationality: '',
            currentAddress: '',
            currentOccupation: '',
            nameOfTheCompany: '',
            jobDescription: '',
            maritalStatus: '',
        },
        academicRecord: [
            {
                academicId: '1',
                academicType: '',
                nameOfTheInstitution: '',
                academicPeriod: {
                    from: '',
                    to: '',
                },
            },
        ],
        familyInfo: [
            {
                familyId: '1',
                relation: '',
                relativeName: '',
                relativeOccupation: '',
                relativeDateOfBirth: '',
            },
        ],
    });
    const [error, setError] = React.useState<FormProps>({
        studyType: '',
        personalInfo: {
            studentNumber: '',
            nameWithInitials: '',
            fullName: '',
            dateOfBirth: '',
            age: '',
            gender: '',
            nic: '',
            passportNumber: '',
            passportExpiryDate: '',
            nationality: '',
            currentAddress: '',
            currentOccupation: '',
            nameOfTheCompany: '',
            jobDescription: '',
            maritalStatus: '',
        },
        academicRecord: [
            {
                academicId: '1',
                academicType: '',
                nameOfTheInstitution: '',
                academicPeriod: {
                    from: '',
                    to: '',
                },
            },
        ],
        familyInfo: [
            {
                familyId: '1',
                relation: '',
                relativeName: '',
                relativeOccupation: '',
                relativeDateOfBirth: '',
            },
        ],
    });

    const isStepOptional = (step: number) => {
        return step === null;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = async () => {
        try {
            // personalInfo validation
            if (activeStep === 1) {
                const personalInfoErrors = {} as FormProps['personalInfo'];
                const personalInfo = form.personalInfo;

                if (personalInfo.nameWithInitials === '') {
                    personalInfoErrors.nameWithInitials = 'Name with initials is required';
                }
                if (personalInfo.fullName === '') {
                    personalInfoErrors.fullName = 'Full name is required';
                }
                if (personalInfo.dateOfBirth === '') {
                    personalInfoErrors.dateOfBirth = 'Date of birth is required';
                }
                if (personalInfo.age === '') {
                    personalInfoErrors.age = 'Age is required';
                }
                if (personalInfo.gender === '') {
                    personalInfoErrors.gender = 'Gender is required';
                }
                if (personalInfo.nic === '') {
                    personalInfoErrors.nic = 'NIC is required';
                }
                if (personalInfo.passportNumber === '') {
                    personalInfoErrors.passportNumber = 'Passport number is required';
                }
                if (personalInfo.passportExpiryDate === '') {
                    personalInfoErrors.passportExpiryDate = 'Passport expiry date is required';
                }
                if (personalInfo.currentAddress === '') {
                    personalInfoErrors.currentAddress = 'Current address is required';
                }
                if (personalInfo.currentOccupation === '') {
                    personalInfoErrors.currentOccupation = 'Current occupation is required';
                }
                if (personalInfo.maritalStatus === '') {
                    personalInfoErrors.maritalStatus = 'Marital status is required';
                }

                if (Object.keys(personalInfoErrors).length > 0) {
                    setError(prevError => ({
                        ...prevError,
                        personalInfo: {
                            ...prevError.personalInfo,
                            ...personalInfoErrors,
                        },
                    }));
                    return;
                }
            }

            // academicRecord validation
            if (activeStep === 2) {
                const academicRecordErrors = [] as FormProps['academicRecord'];
                const academicRecord = form.academicRecord;

                academicRecord.forEach((record) => {
                    const recordErrors = {} as FormProps['academicRecord'][0];
                    if (record.academicType === '') {
                        recordErrors.academicType = 'Academic type is required';
                    }
                    if (record.nameOfTheInstitution === '') {
                        recordErrors.nameOfTheInstitution = 'Name of the institution is required';
                    }
                    if (Object.keys(recordErrors).length > 0) {
                        academicRecordErrors.push(recordErrors);
                    }
                });

                if (academicRecordErrors.length > 0) {
                    setError(prevError => ({
                        ...prevError,
                        academicRecord: academicRecordErrors,
                    }));
                    return;
                }
            }


            // familyInfo validation
            if (activeStep === 3) {
                const familyInfoErrors = [] as FormProps['familyInfo'];
                const familyInfo = form.familyInfo;

                familyInfo.forEach((record) => {
                    const recordErrors = {} as FormProps['familyInfo'][0];
                    if (record.relation === '') {
                        recordErrors.relation = 'Relation is required';
                    }
                    if (record.relativeName === '') {
                        recordErrors.relativeName = 'Relative name is required';
                    }
                    if (record.relativeOccupation === '') {
                        recordErrors.relativeOccupation = 'Relative occupation is required';
                    }
                    if (Object.keys(recordErrors).length > 0) {
                        familyInfoErrors.push(recordErrors);
                    }
                });

                if (familyInfoErrors.length > 0) {
                    setError(prevError => ({
                        ...prevError,
                        familyInfo: familyInfoErrors,
                    }));
                    return;
                }
            }

            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
            // api call
            if (activeStep === steps.length - 1) {
                const response = await POST('applicant/applicant-register', form);
                toast.success(response.data.message ? response.data.message : 'Form submitted successfully');
                setForm({
                    studyType: '',
                    personalInfo: {
                        studentNumber: '',
                        nameWithInitials: '',
                        fullName: '',
                        dateOfBirth: '',
                        age: '',
                        gender: '',
                        nic: '',
                        passportNumber: '',
                        passportExpiryDate: '',
                        nationality: '',
                        currentAddress: '',
                        currentOccupation: '',
                        nameOfTheCompany: '',
                        jobDescription: '',
                        maritalStatus: '',
                    },
                    academicRecord: [
                        {
                            academicId: '1',
                            academicType: '',
                            nameOfTheInstitution: '',
                            academicPeriod: {
                                from: '',
                                to: '',
                            },
                        },
                    ],
                    familyInfo: [
                        {
                            familyId: '1',
                            relation: '',
                            relativeName: '',
                            relativeOccupation: '',
                            relativeDateOfBirth: '',
                        },
                    ],
                })
            }
        } catch (err) {
            console.log('error', err);
            (err as CustomError).response?.data?.message &&
                toast.error((err as CustomError).response?.data?.message ?
                    (err as CustomError).response?.data?.message : 'Something went wrong');
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    React.useEffect(() => {
        // start to type values error will be removed 
        setError({
            studyType: '',
            personalInfo: {
                studentNumber: '',
                nameWithInitials: '',
                fullName: '',
                dateOfBirth: '',
                age: '',
                gender: '',
                nic: '',
                passportNumber: '',
                passportExpiryDate: '',
                currentAddress: '',
                currentOccupation: '',
                maritalStatus: '',
                jobDescription: '',
                nameOfTheCompany: '',
                nationality: ''
            },
            academicRecord: [
                {
                    academicId: '1',
                    academicType: '',
                    nameOfTheInstitution: '',
                    academicPeriod: {
                        from: '',
                        to: '',
                    },
                },
            ],
            familyInfo: [
                {
                    familyId: '1',
                    relation: '',
                    relativeName: '',
                    relativeOccupation: '',
                    relativeDateOfBirth: '',
                },
            ],
        });


    }, [form]);


    const handleReset = () => {
        setActiveStep(0);
        setForm({
            studyType: '',
            personalInfo: {
                studentNumber: '',
                nameWithInitials: '',
                fullName: '',
                dateOfBirth: '',
                age: '',
                gender: '',
                nic: '',
                passportNumber: '',
                passportExpiryDate: '',
                nationality: '',
                currentAddress: '',
                currentOccupation: '',
                nameOfTheCompany: '',
                jobDescription: '',
                maritalStatus: '',
            },
            academicRecord: [
                {
                    academicId: '1',
                    academicType: '',
                    nameOfTheInstitution: '',
                    academicPeriod: {
                        from: '',
                        to: '',
                    },
                },
            ],
            familyInfo: [
                {
                    familyId: '1',
                    relation: '',
                    relativeName: '',
                    relativeOccupation: '',
                    relativeDateOfBirth: '',
                },
            ],
        })
    };


    //! handle form data
    const handleInputChange = (e: { target: { name: string; value: string; }; }, id?: string) => {
        const { name, value } = e.target;

        // all values are UPPER CASE
        name.toUpperCase();

        // Validation for age field: allow only numbers
        if (name === 'age' && isNaN(Number(value))) {
            return;
        }
        // Validation for nationality field: allow only strings
        if (name === 'nationality' && !/^[a-zA-Z\s]*$/.test(value)) {
            return;
        }

        // Validation for nic field: allow only numbers and 'V' or 'v'
        if (name === 'nic' && !/^[\dVv]*$/.test(value)) {
            return;
        }

        if (name === 'dateOfBirth') {
            // Calculate age based on date of birth
            const dob = new Date(value);
            const today = new Date();
            const age = Math.floor((today.getTime() - dob.getTime()) / (1000 * 60 * 60 * 24 * 365));
            // Set age to the age field in the personalInfo object
            setForm(prevForm => ({
                ...prevForm,
                personalInfo: {
                    ...prevForm.personalInfo,
                    age: age.toString(),
                    [name]: value,
                },
            }));
            return;
        }

        if (name === 'studyType') {
            setForm(prevForm => ({
                ...prevForm,
                [name]: value,
            }))
        }

        if (id && id.startsWith('academicRecord')) {
            const parts = id.split('.');
            const index = parseInt(parts[1]);
            const fieldName = parts[2];
            const fromAndTo = parts[3];

            // Check if the field belongs to academicPeriod
            if (fromAndTo === 'from' || fromAndTo === 'to') {
                setForm(prevForm => ({
                    ...prevForm,
                    academicRecord: [
                        ...prevForm.academicRecord.slice(0, index),
                        {
                            ...prevForm.academicRecord[index],
                            academicPeriod: {
                                ...prevForm.academicRecord[index].academicPeriod,
                                [fromAndTo]: value,
                            },
                        },
                        ...prevForm.academicRecord.slice(index + 1),
                    ],
                }));
                return;
            } else {
                setForm(prevForm => ({
                    ...prevForm,
                    academicRecord: [
                        ...prevForm.academicRecord.slice(0, index),
                        {
                            ...prevForm.academicRecord[index],
                            [fieldName]: value,
                        },
                        ...prevForm.academicRecord.slice(index + 1),
                    ],
                }));
            }
        }

        if (id && id.startsWith('familyInfo')) {
            const parts = id.split('.');
            const index = parseInt(parts[1]);
            const fieldName = parts[2];
            setForm(prevForm => ({
                ...prevForm,
                familyInfo: [
                    ...prevForm.familyInfo.slice(0, index),
                    {
                        ...prevForm.familyInfo[index],
                        [fieldName]: value,
                    },
                    ...prevForm.familyInfo.slice(index + 1),
                ],
            }));
        }

        switch (id) {
            case 'personalInfo':
                setForm(prevForm => ({
                    ...prevForm,
                    personalInfo: {
                        ...prevForm.personalInfo,
                        [name]: value,
                    },
                }));
                break;
            case 'academicRecord':
                setForm(prevForm => ({
                    ...prevForm,
                    academicRecord: {
                        ...prevForm.academicRecord,
                        [name]: value,
                    },
                }));
                break;
            default:
                break;
        }


    }

    React.useEffect(() => {
        scrollTo(0, 0);
    }, [activeStep]);

    return (
        <div>
            <Stepper
                activeStep={activeStep}
                className="flex-wrap md:flex-nowrap text-left pb-5"
            >
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <span className="text-xs text-primaryColor">Optional</span>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel className="" {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <div className="py-5">
                    <div className="text-center">
                        <h2 className="text-2xl font-semibold">
                            All steps completed - you&apos;re finished
                        </h2>
                        <button onClick={handleReset} className="mt-5">
                            Reset
                        </button>
                    </div>
                </div>
            ) : (
                <div className='space-y-5'>
                    <div>
                        {activeStep === 0
                            ? <StudyType form={form} handleInputChange={handleInputChange} />
                            : activeStep === 1
                                ? <PersonalInfo form={form} handleInputChange={handleInputChange} error={error} />
                                : activeStep === 2
                                    ? <AcademicRecord form={form} handleInputChange={handleInputChange} setForm={setForm} error={error} />
                                    : activeStep === 3
                                        ? <FamilyInfo form={form} handleInputChange={handleInputChange} setForm={setForm} error={error} />
                                        : null}
                    </div>
                    <div className="flex pb-4">
                        <button
                            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            Back
                        </button>
                        <div className="flex-1" />
                        {isStepOptional(activeStep) && (
                            <button
                                className="mr-4 px-4 py-2 bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={handleSkip}>
                                Skip
                            </button>
                        )}
                        <button
                            onClick={handleNext}
                            className='px-4 py-2 bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed'
                            disabled={
                                (activeStep === 0 && form.studyType === '') ||
                                (activeStep === 1 && form.personalInfo.nameWithInitials === '') ||
                                (activeStep === 1 && form.personalInfo.fullName === '') ||
                                (activeStep === 1 && form.personalInfo.dateOfBirth === '') ||
                                (activeStep === 1 && form.personalInfo.age === '') ||
                                (activeStep === 1 && form.personalInfo.gender === '') ||
                                (activeStep === 1 && form.personalInfo.nic === '') ||
                                (activeStep === 1 && form.personalInfo.passportNumber === '') ||
                                (activeStep === 1 && form.personalInfo.passportExpiryDate === '') ||
                                (activeStep === 1 && form.personalInfo.currentAddress === '') ||
                                (activeStep === 1 && form.personalInfo.currentOccupation === '') ||
                                (activeStep === 1 && form.personalInfo.maritalStatus === '') ||
                                (activeStep === 2 && form.academicRecord.some((record) => record.academicType === '' || record.nameOfTheInstitution === '')) ||
                                (activeStep === 3 && form.familyInfo.some((record) => record.relation === '' || record.relativeName === '' || record.relativeOccupation === ''))
                            }
                        >
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Form;
