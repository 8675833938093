export type NavigationItem = {
    title: string;
    path?: string;
    icon?: string;
    type?: string;
    children?: Array<GroupItem | ChildItem>;
};

export interface ChildItem {
    title: string;
    path: string;
    icon: string;
    type: string;
    children?: ChildItem[];
}

export interface GroupItem {
    title: string;
    icon: string;
    path?: string;
    type: string;
    children: ChildItem[];
}

const navigationConfig: NavigationItem[] = [
    {
        title: 'Home',
        path: '/',
        icon: 'home',
        type: 'link'
    },
    {
        title: 'Services',
        icon: 'cogs',
        type: 'group',
        children: [
            {
                title: 'JAPANESE LANGUAGE CLASSES',
                icon: 'cogs',
                type: 'group',
                path: '/japanese-language-classes',
                children: [
                    {
                        title: 'Full Time Course',
                        path: '/japanese-language-classes/full-time-course',
                        icon: 'cogs',
                        type: 'link'
                    },
                    {
                        title: 'Part Time Course',
                        path: '/japanese-language-classes/part-time-course',
                        icon: 'cogs',
                        type: 'link'
                    },
                    {
                        title: 'Advance Course',
                        path: '/japanese-language-classes/advance-course',
                        icon: 'cogs',
                        type: 'link'
                    }
                ],
            },
            {
                title: 'CONSULTATION',
                path: '/consultation',
                icon: 'cogs',
                type: 'link'
            },
            {
                title: 'MASTER’S PROGRAM',
                path: '/masters-program',
                icon: 'cogs',
                type: 'link'
            }
        ]
    },
    {
        title: 'Gallery',
        path: '/gallery',
        icon: 'image',
        type: 'link'
    },
    {
        title: 'About',
        path: '/about',
        icon: 'info',
        type: 'link'
    },
    {
        title: 'Contact',
        path: '/contact',
        icon: 'phone',
        type: 'link'
    },
]

export default navigationConfig;