import React, { useEffect } from "react";
import { CommonHeader } from "../../../../../components/CommonHeader";

const PartTimeCourse: React.FC = () => {
    useEffect(() => {
        scrollTo(0, 0);
    }, []);
    return (
        <div className="min-h-[calc(100vh-48px-3.85rem)]" >
            <CommonHeader title='Part Time Course' bgImage='bg-Service-5'
                mainPath={{
                    name: 'JAPANESE LANGUAGE CLASSES',
                    path: '/japanese-language-classes'

                }}
            />
            <div className="bg-white">
                <div className="container py-10 flex justify-center flex-col items-center space-y-4">
                    <div className='parallelogram' />
                    <div className="flex items-center md:gap-3 text-[28px] md:text-[40px] font-bold flex-wrap">
                        <span className="text-[#525967]"> PART TIME COURSE</span>
                        <span className="text-primaryColor"> OVERVIEW </span>
                    </div>
                    <div className="w-[75%] text-justify font-bold text-[18px]">
                        The course is designed for those who are keen on studying in Japan.
                        This course will cover the Japanese Language Proficiency required by Japanese Higher Education Institutions in Japan.
                        Upon successful completion of the course, students will be competent and familiar with general Japanese communication skills.
                    </div>
                </div>
            </div>
            <div>
                <div className='flex flex-col justify-center'>
                    <div className='bg-cover bg-top h-full fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-Service-9' />
                    <div className="container z-10">
                        <div className="px-5 md:px-20 pt-16 pb-8 text-white text-center">
                            <div className="text-[20px] md:text-[28px]"> BROADEN YOUR CONVERSATION TO TOPICS OF INTEREST</div>

                            <div className="text-[28px] md:text-[48px] font-bold">IN JAPAN</div>
                        </div>
                    </div>
                    <div className="w-full bg-gray-100  mt-36 pb-20">
                        <div className="container z-10  -mt-36">
                            <div className="shadow-xl">
                                <section>
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">

                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                COURSE STRUCTURE
                                            </span>
                                        </div>
                                        <div className="bg-white flex flex-col items-center justify-center text-black space-y-10 p-5 border-b-[6px] border-primaryColor">
                                            <span className="text-justify font-bold text-[0.95rem]">
                                                The main objective of this course is to prepare for the Japanese Language Proficiency Tests (JLPT, NAT, JLCT). Primarily focusing on the Japanese Language Proficiency Test N5 ~ N4 examinations.
                                            </span>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className="w-full bg-white grid grid-cols-1 md:grid-cols-2">
                                        <div className="flex flex-col items-center md:pt-12 pt-5 md:justify-start justify-center  p-5 min-h-[250px] md:col-span-2">
                                            <div className="w-full overflow-auto block">
                                                <table className="w-full text-center border-collapse border border-slate-500 overflow-hidden">
                                                    <tbody className="text-center">
                                                        <tr className="border-2 border-gray-300">
                                                            <td className="border-2 border-gray-300 bg-primaryColor text-white min-w-80" rowSpan={2}>
                                                                <span className="">
                                                                    Aims
                                                                </span>
                                                            </td>
                                                            <td className="border-2 border-gray-300 py-2 font-bold">
                                                                <span className="">
                                                                    Beginner
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="border-2 border-gray-300" >
                                                            <td className="border-2 border-gray-300 py-2" colSpan={2}>
                                                                <span className="">
                                                                    Basic Grammar
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="border-2 border-gray-300" >
                                                            <td className="border-2 border-gray-300 bg-primaryColor text-white">
                                                                <span className="">
                                                                    Curriculum
                                                                </span>
                                                            </td>
                                                            <td className="border-2 border-gray-300 pl-5 py-2 w-[100%]">
                                                                <span className="text-left whitespace-nowrap md:whitespace-normal">
                                                                    <p>1. Developing basic skills in listening, speaking, reading and writing.</p>
                                                                    <p>2. Hiragana, Katakana and basic Kanji introduction</p>
                                                                    <p>3. Basic grammar structures</p>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="border-2 border-gray-300" >
                                                            <td className="border-2 border-gray-300 bg-primaryColor text-white">
                                                                <span className="">
                                                                    Duration
                                                                </span>
                                                            </td>
                                                            <td className="border-2 border-gray-300 py-2">
                                                                <span className="leading-5">

                                                                    <p>4 months course</p>
                                                                    <p>(8 hours x 2 days x 4 months = 256 study hours)</p>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="past-paper">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">
                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                ENTRY REQUIREMENTS
                                                & FEE
                                            </span>
                                        </div>
                                        <div className="bg-white flex flex-col items-center justify-center text-black pl-5 py-5 md:pl-10 md:py-10 pr-4 border-t-[6px] border-primaryColor">
                                            <div className="text-left ml-6 space-y-5 font-bold text-[0.95rem]">
                                                <div>
                                                    <span className="-ml-6">
                                                        <i className="fas fa-chevron-right"></i>
                                                    </span>
                                                    <span className="pl-[0.8rem]">
                                                        For those who are keen on learning Japanese language and for those who wish to continue their higher studies in Japan
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="-ml-6">
                                                        <i className="fas fa-chevron-right"></i>
                                                    </span>
                                                    <span className="pl-[0.8rem]">
                                                        Availability for a part-time course (Weekends Only)
                                                    </span>
                                                </div>
                                                {/* <div>
                                                    <span className="-ml-6">
                                                        <i className="fas fa-chevron-right"></i>
                                                    </span>
                                                    <span className="pl-[0.8rem]">
                                                        Estimated Course fee is 12000LKR
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartTimeCourse;
