import React, { useEffect } from "react";
import { FormProps } from "../../../../models/form.interface";
import { GET } from "../../../auth/AxiosHelper";
import InputField from "../../../components/form-fields/inputField";
import DownloadExcel from "../../../components/DownloadExcel";
const ApplicationMaster: React.FC = () => {
    const [gender, setGender] = React.useState<string>(""); // Male, Female, Other
    const [studyType, setStudyType] = React.useState<string>("");//japanees laguage School, master Language School
    const [nic, setNic] = React.useState<string>("");
    const [ageFilter, setAgeFilter] = React.useState<{ operator: string; value: number | [number, number]; value2?: number }>({ operator: "", value: 0 }); // greaterthan, lowerthan, equal
    const [allApplications, setAllApplications] = React.useState<FormProps[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [expandedRows, setExpandedRows] = React.useState<Set<number>>(new Set());

    const fetchAllApplications = async () => {
        try {
            setLoading(true);
            const params = {
                "gender": gender,
                "studyType": studyType,
                "nic": nic,
                "ageFilter": ageFilter
            }
            if (ageFilter.operator === 'RANGE') {
                params.ageFilter = { operator: 'RANGE', value: [Number(ageFilter.value), Number(ageFilter.value2)] };
            } else if (ageFilter.operator !== '') {
                params.ageFilter = { operator: ageFilter.operator, value: ageFilter.value };
            }

            const res = await GET("applicant/get-applicants", undefined, params);
            setAllApplications(res.data.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        fetchAllApplications();
    }, []);

    React.useEffect(() => {
        fetchAllApplications();
    }, [gender, studyType, nic, ageFilter]);

    // Toggle row expansion
    const toggleRowExpansion = (index: number) => {
        const newExpandedRows = new Set(expandedRows);
        if (expandedRows.has(index)) {
            newExpandedRows.delete(index);
        } else {
            newExpandedRows.add(index);
        }
        setExpandedRows(newExpandedRows);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="min-h-screen container py-10 space-y-5">
            <div className="flex flex-col md:flex-row md:items-center justify-between md:gap-0">
                <div>
                    <h1 className="text-[24px] text-primaryColor">Application Master</h1>
                    <span className="text-gray-500">View all applications</span>
                </div>
                <div className="flex items-center gap-3">
                    <div className="md:block hidden">
                        <DownloadExcel data={allApplications} />
                    </div>
                    <button
                        className="bg-primaryColor w-full text-white px-4 py-2 rounded-md md:block hidden"
                        onClick={() => {
                            setGender("");
                            setStudyType("");
                            setNic("");
                            setAgeFilter({ operator: '', value: 0 });
                        }}
                    >
                        Reset Filters
                    </button>
                </div>
            </div>
            <div>
                <div className="flex space-x-5">
                    <div className="flex flex-col w-full md:flex-row gap-2">
                        <InputField
                            label='Gender'
                            placeholder='Gender'
                            type='select'
                            value={gender}
                            id='gender'
                            name='gender'
                            options={[
                                { value: '', label: 'All' },
                                { value: 'MALE', label: 'Male' },
                                { value: 'FEMALE', label: 'Female' },
                                { value: 'OTHER', label: 'Other' },
                            ]}
                            onChange={(e) => setGender(e.target.value)}

                        />
                        <InputField
                            label='Study Type'
                            placeholder='Study Type'
                            type='select'
                            value={studyType}
                            id='studyType'
                            name='studyType'
                            options={
                                [
                                    { value: '', label: 'All' },
                                    { value: 'JAPANESE_LANGUAGE_SCHOOL', label: 'Japanese Language School' },
                                    { value: 'MASTER_LANGUAGE_SCHOOL', label: 'Master Language School' },
                                ]
                            }
                            onChange={(e) => setStudyType(e.target.value)}
                        />
                        <InputField
                            label='NIC'
                            placeholder='NIC'
                            type='text'
                            value={nic}
                            id='nic'
                            name='nic'
                            onChange={(e) => setNic(e.target.value)}
                        />
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="ageFilter" className="text-primaryColor font-normal">
                                Age Filter
                            </label>
                            <div className="flex items-center w-full">
                                <InputField
                                    placeholder='Age'
                                    type='number'
                                    value={String(ageFilter.value)}
                                    id='age'
                                    name='ageFilter'
                                    onChange={(e) => setAgeFilter({ ...ageFilter, value: parseInt(e.target.value) })}
                                />
                                {ageFilter.operator === 'RANGE' && (
                                    <>
                                        <span className='px-2'>to</span>
                                        <InputField
                                            // label='Age Filter'
                                            placeholder='Age Filter'
                                            type='number'
                                            value={String(ageFilter.value2)}
                                            id='ageFilter'
                                            name='ageFilter'
                                            onChange={(e) => setAgeFilter({ ...ageFilter, value2: parseInt(e.target.value) })}
                                        />
                                    </>
                                )}
                                <InputField
                                    // label='Age Filter'
                                    placeholder='Age Filter'
                                    type='select'
                                    value={ageFilter.operator}
                                    id='ageFilter'
                                    name='ageFilter'
                                    options={
                                        [
                                            { value: '', label: 'None' },
                                            { value: 'RANGE', label: 'Range' },
                                            { value: 'GREATER_THAN', label: 'Greater Than' },
                                            { value: 'LOWER_THAN', label: 'Lower Than' },
                                            { value: 'EQUAL', label: 'Equal' },
                                        ]
                                    }
                                    onChange={(e) => setAgeFilter({ ...ageFilter, operator: e.target.value })}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3 flex flex-col gap-3 md:hidden">
                    <div className="block md:hidden">
                        <DownloadExcel data={allApplications} />
                    </div>
                    <button
                        className="bg-primaryColor w-full text-white px-4 py-2 rounded-md"
                        onClick={() => {
                            setGender("");
                            setStudyType("");
                            setNic("");
                            setAgeFilter({ operator: '', value: 0 });
                        }}
                    >
                        Reset Filters
                    </button>
                </div>
            </div>
            <div className="block overflow-auto w-full bg-white rounded-md p-1">
                <table className="border-collapse table-auto w-full whitespace-nowrap uppercase">
                    <thead className=''>
                        <tr>
                            <th className='px-4 py-2'></th>
                            <th className='px-4 py-2'> Applicant Id</th>
                            <th className='px-4 py-2'> Study Type</th>
                            <th className='px-4 py-2'> Studuent Number</th>
                            <th className='px-4 py-2'> Name With Initials</th>
                            <th className='px-4 py-2'> Full Name</th>
                            <th className='px-4 py-2'> Date Of Birth</th>
                            <th className='px-4 py-2'> Age</th>
                            <th className='px-4 py-2'>Gender</th>
                            <th className='px-4 py-2'>Nationality</th>
                            <th className='px-4 py-2'>Current Address</th>
                            <th className='px-4 py-2'>NIC</th>
                            <th className='px-4 py-2'>Passport Number</th>
                            <th className='px-4 py-2'>Passport Expiry Date</th>
                            <th className='px-4 py-2'>Current Occupation</th>
                            <th className='px-4 py-2'>Name Of The Company</th>
                            <th className='px-4 py-2'>Job Description</th>
                            <th className='px-4 py-2'>Marital Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? <tr><td colSpan={17} className='text-center'>Loading...</td></tr>
                            : allApplications?.length > 0 && allApplications.map((application, index) => (
                                <React.Fragment key={application.applicantId}>
                                    <tr className={`border-b ${index % 2 === 0 ?
                                        'bg-gray-100 hover:bg-gray-300' :
                                        'bg-gray-100 hover:bg-gray-300'
                                        }
                                        h-16    
                                        ${expandedRows.has(index) ? 'bg-gray-400 text-white' : ''}
                                        `}
                                    >
                                        <td
                                            className={`px-4 py-2 cursor-pointer
                                            ${expandedRows.has(index) ? 'bg-gray-400 text-white' : ''}
                                            
                                            `}
                                            onClick={() => toggleRowExpansion(index)}
                                        >
                                            {expandedRows.has(index) ? '-' : '+'}
                                        </td>
                                        <td className=' px-4 py-2'>{application.applicantId}</td>
                                        <td className=' px-4 py-2'>{application.studyType === 'JAPANESE_LANGUAGE_SCHOOL' ? 'Japanese' : `Master's`}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.studentNumber || 'N/A'}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.nameWithInitials}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.fullName}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.dateOfBirth}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.age}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.gender}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.nationality}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.currentAddress}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.nic}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.passportNumber}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.passportExpiryDate}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.currentOccupation}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.nameOfTheCompany}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.jobDescription}</td>
                                        <td className=' px-4 py-2'>{application.personalInfo.maritalStatus}</td>
                                    </tr>
                                    <>
                                        {expandedRows.has(index) && (
                                            <>
                                                <tr>
                                                    <td colSpan={4} className=' px-4 py-2'>
                                                        Academic Records
                                                        <div className="grid grid-cols-1 px-4 py-2 gap-4">
                                                            {application.academicRecord.map((record, recordIndex) => (
                                                                <div
                                                                    key={recordIndex}
                                                                    className="bg-white border rounded-md border-slate-700 px-4 py-2 gap-4"
                                                                >
                                                                    <div>
                                                                        <p>{record.academicType}</p>
                                                                        <p>{record.nameOfTheInstitution}</p>
                                                                        <p>{record.academicPeriod.from} - {record.academicPeriod.to}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={4} className=' px-4 py-2'>
                                                        Family Info
                                                        <div className="grid grid-cols-1 px-4 py-2 gap-4">
                                                            {application.familyInfo.map((family, familyIndex) => (
                                                                <div
                                                                    key={familyIndex}
                                                                    className="bg-white border rounded-md border-slate-700 px-4 py-2 gap-4"
                                                                >
                                                                    <div>
                                                                        <p>{family.relation}</p>
                                                                        <p>{family.relativeName}</p>
                                                                        <p>{family.relativeOccupation}</p>
                                                                        <p>{family.relativeDateOfBirth}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </>
                                </React.Fragment>
                            ))

                        }
                    </tbody>
                </table>
            </div>

        </div >
    );
}

export default ApplicationMaster;