import React, { useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { AdvanceCourse, Consultation, FullTimeCourse, JapaneseLanguage, MastersProgram, PartTimeCourse } from './pages/services';
import HandleAuth from './auth/HandleAuth';
import { AppProvider } from '../contexts/AppContext';
import { AboutUs, ContactUs, Gallery, Home, Register } from './pages';
import { Error404Page, Login, Unauthorized } from './main';
import { Dashboard, GalleryMater, Profile, ApplicationMaster, UserMaster } from './pages/admin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App: React.FC = () => {

  // userType = "SUPER_ADMIN" | "ADMIN" | "USER" | "GUEST"

  useEffect(() => {
    if (window.location.pathname === '/admin-login' || window.location.pathname === '/admin-signin') {
      window.location.href = '/#/admin-login';
      <Routes>
        <Route path="/admin-login" element={<Login />} />
      </Routes>;
    }
  }, []);
  return (
    <AppProvider>
      <HashRouter>
        <ToastContainer />
        <Routes>
          {/* Guest Routes */}
          <Route element={<HandleAuth userType="GUEST" />}>
            <Route path="/" element={<Home />} />
            <Route path="japanese-language-classes" element={<JapaneseLanguage />} />
            <Route path="consultation" element={<Consultation />} />
            <Route path="masters-program" element={<MastersProgram />} />
            <Route path="japanese-language-classes/full-time-course" element={<FullTimeCourse />} />
            <Route path="japanese-language-classes/part-time-course" element={<PartTimeCourse />} />
            <Route path="japanese-language-classes/advance-course" element={<AdvanceCourse />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="register" element={<Register />} />
          </Route>

          {/* Admin User */}
          <Route path="/admin-login" element={<Login />} />
          <Route element={<HandleAuth userType="SUPER_ADMIN" />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="gallery-master" element={<GalleryMater />} />
            <Route path="user-master" element={<UserMaster />} />
            <Route path="application-master" element={<ApplicationMaster />} />
          </Route>

          {/* Common Routes */}
          <Route path="*" element={<Error404Page />} />
          <Route path="unauthorized" element={<Unauthorized />} />
        </Routes>
      </HashRouter>
    </AppProvider>
  );
};

export default App;
