import React, { useEffect } from "react";
import { CommonHeader } from "../../../../components/CommonHeader";
const data = [
    {
        id: 1,
        name: 'Duration',
        value: '2 years'
    },
    {
        id: 2,
        name: 'Medium',
        value: 'English / Japanese'
    },
    {
        id: 3,
        name: 'Intake',
        value: 'April / October'
    }
]

const curriculum = [
    {
        id: 1,
        name: 'ERP',
        value: 'Enterprise Resource Planning'
    },
    {
        id: 1,
        name: 'Business Data Analytics',
        value: ''
    },
    {
        id: 1,
        name: 'Global Entrepreneurship',
        value: ''
    },
    {
        id: 1,
        name: 'Web Systems Development',
        value: ''
    },
    {
        id: 1,
        name: 'Network Administration',
        value: ''
    },
    {
        id: 1,
        name: 'IT Manga & Anime',
        value: ''
    },
    {
        id: 1,
        name: 'Education',
        value: ''
    },
    {
        id: 1,
        name: 'IT Tourism',
        value: ''
    },
    {
        id: 1,
        name: 'Artificial Intelligence',
        value: ''
    },
    {
        id: 1,
        name: 'Fintech',
        value: ''
    },
    {
        id: 1,
        name: 'Content Marketing',
        value: ''
    },
    {
        id: 1,
        name: 'Agriculture',
        value: ''
    },
    {
        id: 1,
        name: 'Health & Medical',
        value: ''
    }
]


const employment = [
    {
        id: 1,
        name: 'CIO',
        value: 'Enterprise Resource Planning'
    },
    {
        id: 1,
        name: 'Project Manager',
        value: ''
    },
    {
        id: 1,
        name: 'Senior Software Engineer',
        value: ''
    },
    {
        id: 1,
        name: 'Web System Architect',
        value: ''
    },
    {
        id: 1,
        name: 'System Integration Consultant',
        value: ''
    },
    {
        id: 1,
        name: 'Web Marketing Professional',
        value: ''
    },
    {
        id: 1,
        name: 'System Architect',
        value: ''
    },
    {
        id: 1,
        name: 'Information Security Consultant',
        value: ''
    },
    {
        id: 1,
        name: 'Content Production Manage',
        value: ''
    },
    {
        id: 1,
        name: 'Data Analyst',
        value: ''
    }
]

const entryRequirements = [
    {
        id: 1,
        name: 'Bachelor`s Degree',
        value: 'ANY FIELD'
    },
    {
        id: 1,
        name: 'IELTS',
        value: 'NOT REQUIRED'
    },
    {
        id: 1,
        name: 'Scholarship Program for International students',
        value: ''
    }
]
const MastersProgram: React.FC = () => {
    useEffect(() => {
        scrollTo(0, 0);
    }, []);

    return (
        <div className="min-h-[calc(100vh-48px-3.85rem)]" >
            <CommonHeader title='MASTER’S PROGRAM' bgImage='bg-Service-3' />
            <div className="bg-white">
                <div className="container py-10 flex justify-center flex-col items-center space-y-4">
                    <div className='parallelogram' />
                    <div className="flex items-center md:gap-3 text-[28px] md:text-[40px] font-bold flex-wrap">
                        <span className="text-[#525967]">MASTER’S PROGRAMS</span>
                        <span className="text-primaryColor"> OVERVIEW </span>
                    </div>
                    <div className="md:w-[95%] xl:w-[65%] text-[1.05rem] font-bold text-justify leading-8">
                        Nippon Lanka Japanese Language School affiliated with Japanese Universities offers Sri Lankan students a Master`s Program in Information Technology exclusively in English Medium.
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <span className="text-primaryColor font-bold text-[1.5rem]">Master Of Science In Information Technology </span>
                        <div className="h-[2px] w-2/3 bg-primaryColor my-2" />
                    </div>
                    <div className="pb-10 space-y-4">
                        {data.length > 0 && data?.map((item, index) => (
                            <div className="flex items-center" key={index}>
                                <span className=" bg-primaryColor py-3 px-5 text-white rounded-md">
                                    <i className="fas fa-chevron-right"></i>
                                </span>
                                <div className="pl-[0.8rem] flex flex-col">
                                    <span className="text-base font-bold">
                                        {item.name}
                                    </span>
                                    <span className="text-sm">
                                        {item.value}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <div className='flex flex-col justify-center'>
                    <div className='bg-cover bg-top h-full fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-Service-11' />
                    <div className="container z-10">
                        <div className="px-5 md:px-20 pt-16 pb-8 text-white text-center">
                            <div className="text-[20px] md:text-[28px]">THE PINNACLE OF THE FIELD OF APPLIED IT</div>

                            <div className="text-[28px] md:text-[48px] font-bold">IN JAPAN</div>
                        </div>
                    </div>
                    <div className="w-full bg-gray-100  mt-36 pb-20">
                        <div className="container z-10  -mt-36">
                            <div className="shadow-xl">
                                <section id="for-students">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">
                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                CURRICULUM
                                            </span>
                                        </div>
                                        <div className="bg-white  p-5 border-b-[8px] border-primaryColor">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {curriculum.length > 0 && curriculum?.map((item, index) => (
                                                    <div className="text-left font-bold text-[0.9rem] ml-6" key={index}>
                                                        <span className="pr-3 -ml-[1.29rem]">
                                                            <i className="fas fa-chevron-right"></i>
                                                        </span>
                                                        <span>
                                                            {item.name}
                                                            {item.value !== '' && <span className="text-gray-500 text-[12px]"> - {item.value}</span>}

                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="for-business">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">

                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                EMPLOYMENT
                                            </span>

                                            <span className="text-[20px] md:text-[28px] font-thin">
                                                GRADUATES HAVE THE OPPORTUNITY TO WORK IN IT
                                            </span>
                                        </div>
                                        <div className="bg-white  p-5 border-b-[8px] border-primaryColor">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                {employment.length > 0 && employment?.map((item, index) => (
                                                    <div className="text-left font-bold text-[0.9rem] ml-6" key={index}>
                                                        <span className="pr-3 -ml-[1.29rem]">
                                                            <i className="fas fa-chevron-right"></i>
                                                        </span>
                                                        <span>
                                                            {item.name}
                                                            {item.value !== '' && <span className="text-gray-500 text-[12px]"> - {item.value}</span>}

                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section id="past-paper">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center md:pt-12 pt-0 md:justify-start justify-center text-white p-5 min-h-[250px]">
                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                ENTRY REQUIREMENTS
                                            </span>
                                        </div>
                                        <div className="bg-white  p-5 border-b-[8px] border-primaryColor">
                                            <div className="grid grid-cols-1 gap-4">
                                                {entryRequirements.length > 0 && entryRequirements?.map((item, index) => (
                                                    <div className="text-left font-bold text-[0.9rem] ml-6" key={index}>
                                                        <span className="pr-3 -ml-[1.29rem]">
                                                            <i className="fas fa-chevron-right"></i>
                                                        </span>
                                                        <span className="text-[1.2rem]">
                                                            {item.name}
                                                        </span>
                                                        {item.value !== '' && <p className="text-gray-500 text-[14px]"> {item.value}</p>}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MastersProgram;
