import React from 'react'
import { motion } from 'framer-motion';
import { container, item } from '../../Constant';
import { Link } from 'react-router-dom';
interface CommonHeaderProps {
    title: string;
    bgImage: string;
    mainPath?: {
        name?: string;
        path?: string;
    }
}

const CommonHeader: React.FC<CommonHeaderProps> = ({ title, bgImage, mainPath }) => {
    return (
        <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className={`bg-cover bg-center bg-no-repeat flex flex-col items-center justify-center h-[440px] uppercase ${bgImage ? bgImage : 'bg-bgContact-us'}`}
        >
            <motion.div
                animate={{
                    opacity: 1,
                    left: 0,
                    right: 0,
                }}
                transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                    delay: 0.2,
                }}
                variants={item}
                className="text-[36px] md:text-[48px] text-white font-semibold text-center tracking-tight">
                {title ? title : 'Header Title'}
            </motion.div>
            <motion.div
                animate={{
                    opacity: 1,
                    left: 0,
                    right: 0,
                }}
                transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                    delay: 0.5,
                }}
                variants={item}
                className="text-[14px] text-white text-right space-y-2 tracking-wide space-x-2 flex items-center flex-wrap justify-center">
                <div>
                    <i className='fa fa-home'> </i>
                </div>
                {mainPath &&
                    <div className='space-x-2'>
                        <i className='fa fa-arrow-right text-[#b3b0b0bd]'></i>
                        <Link to={mainPath.path ? mainPath.path : '/'} className='font-medium text-[#ffffffe3] hover:text-[#b3b0b0bd] cursor-pointer'>
                            {mainPath.name ? mainPath.name : 'Main Path'}
                        </Link>
                    </div>
                }
                <div className='space-x-2'>
                    <i className='fa fa-arrow-right text-[#b3b0b0bd]'></i>
                    <span className='font-medium text-[#b3b0b0e3]'>{title ? title : 'Header Title'}</span>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default CommonHeader;