import React, { useEffect } from "react";
import { GET, POST } from "../../../auth/AxiosHelper";
import { toast } from "react-toastify";
import { CustomError, GalleryProps } from "../../../../models/global.interface";
import InputField from "../../../components/form-fields/inputField";
import OldGallery from "./widgets/oldGallery";

const GalleryMater: React.FC = () => {
    const [title, setTitle] = React.useState<string>("");
    const [images, setImages] = React.useState<File[]>([]);
    const [imageUrls, setImageUrls] = React.useState<string[]>([]);
    const [galleryData, setGalleryData] = React.useState<GalleryProps[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            const validFiles = files.filter((file) => file.size <= 1024 * 1024 * 2);
            const exceedingSizeFiles = files.filter((file) => file.size > 1024 * 1024 * 2);
            if (exceedingSizeFiles.length) {
                toast.error(`The following files exceed the maximum size limit of 2MB and will not be added: ${exceedingSizeFiles.map(file => file.name).join(", ")}`);
            }

            setImages((prevImages) => [...prevImages, ...validFiles]);
            const urls = validFiles.map((file) => URL.createObjectURL(file));
            setImageUrls((prevUrls) => [...prevUrls, ...urls]);
        }
    }

    const handleRemoveImage = (url: string) => {
        const prevUrls = imageUrls;
        setImageUrls((prevUrls) => prevUrls.filter((prevUrl) => prevUrl !== url));
        setImages((prevImages) => prevImages.filter((_, index) => prevUrls[index] !== url));
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append("title", title);
            images.forEach((image) => {
                formData.append("images", image);
            });
            const res = await POST("/gallery/upload", formData, { contentType: "multipart/form-data" });

            if (res.status === 201) {
                setTitle("");
                setImages([]);
                setImageUrls([]);
                toast.success("Gallery added successfully");
            }
        } catch (err) {
            console.log(err);

            toast.error(`${(err as CustomError).response?.data?.message || (err as CustomError).message}` || "Something went wrong");
        }
    }

    const fetchGalleryData = async () => {
        try {
            const res = await GET('/gallery/all');
            if (res.status === 200) {

                // PROCESS.env.REACT_APP_API_URL + '/uploads/' + image
                const data = res.data.data.map((item: GalleryProps) => {
                    return {
                        ...item,
                        images: item.images.map((image: string) => process.env.REACT_APP_API_URL + '/' + image)
                    }
                });
                setGalleryData(data);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchGalleryData();
    }, []);

    // properly image show in the frontend
    useEffect(() => {
        return () => {
            imageUrls.forEach((url) => URL.revokeObjectURL(url));
        };
    }, [imageUrls]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="min-h-screen container py-10 space-y-5">
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-[24px] text-primaryColor">Gallery Master</h1>
                    <span className="text-gray-500">Add New gallery</span>
                </div>
            </div>
            <div className="flex flex-col md:flex-row space-y-5 md:space-x-5 md:items-end">
                <div className="flex flex-col w-full gap-2">
                    <label htmlFor="title" className="text-primaryColor font-normal">
                        Title  <span className='text-[11px]'>(Cannot Duplicate)</span>
                    </label>
                    <InputField
                        id="title"
                        name="title"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Title"
                    />
                </div>
                <div>
                    <label
                        htmlFor="file"
                        className="flex cursor-pointer items-center whitespace-nowrap justify-center bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out gap-10 px-4 py-2 w-full"
                    >
                        <i className="fas fa-upload"></i>Choose Images
                    </label>
                    <input
                        type="file"
                        id="file"
                        multiple
                        onChange={handleImageChange}
                        accept="image/*"
                        className="hidden"
                    />
                </div>
                <button
                    className="px-4 py-2 whitespace-nowrap bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={handleSubmit}
                    disabled={!title || !images.length}
                >
                    Add New Gallery
                </button>
            </div>
            <span className="text-primaryColor font-normal text-[11px]">
                Can be add one section at a time, also can be upload multiple images also, but one image size should be less than 2MB
            </span>
            {(title || imageUrls.length > 0) && (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                    {title && (
                        <div className="md:col-span-3">
                            <h2 className="text-primaryColor font-normal uppercase">{title}</h2>
                        </div>
                    )}
                    <div className=" md:col-span-3">
                        <div className="h-[2px] w-full bg-secondaryColor flex items-center justify-center"></div>
                    </div>
                    {imageUrls.map((url, index) => (

                        <div key={index} className='relative w-full h-[200px] lg:h-[300px] rounded-lg overflow-hidden'>
                            <button
                                className=" absolute top-2 right-2 text-white bg-red-500 rounded-full p-1 cursor-pointer w-[30px]"
                                onClick={() => handleRemoveImage(url)}
                            >
                                <i className="fas fa-times"></i>
                            </button>
                            <img src={url} alt="" className='w-full h-full object-cover' />
                        </div>
                    ))}
                </div>
            )
            }

            <OldGallery loading={loading} galleryData={galleryData} />
        </div >
    );
}

export default GalleryMater;