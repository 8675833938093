import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './navbar.css';
import { NavigationItem } from '../../routes/navigationConfig';
import { Tooltip } from '@mui/material';
interface NavBarProps {
    navigation: NavigationItem[];
}

const NavBar: React.FC<NavBarProps> = ({ navigation }) => {
    const [open, setOpen] = React.useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [subItem, setSubItem] = useState(false);
    const [subItem2, setSubItem2] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleOpen = () => {
        if (open) {
            // If the menu is open, close it with animation
            const mobileMenuBg = document.getElementById('mobile-menu-bg');
            const mobileMenu = document.getElementById('mobile-menu');
            if (mobileMenuBg && mobileMenu) {
                mobileMenu.classList.remove('slide-in-right');
                mobileMenu.classList.add('slide-out-right');
                setTimeout(() => {
                    setOpen(false);
                }, 600); // Animation duration
            }
        } else {
            // If the menu is closed, open it
            setOpen(true);
        }
    }

    const handleSubItem = () => {
        setSubItem(!subItem);
    };

    const handleSubItem2 = () => {
        setSubItem2(!subItem2);
    };

    return (
        <>
            <nav
                className={`fixed w-full transition-colors z-[99] duration-300 ${scrolling ? 'bg-[#640a21cf]' : 'bg-primaryColor'
                    }`}
            >
                <div className='w-full bg-primaryColor h-[38px]'>
                    <div className="container py-1">
                        <div id="google_translate_element"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="flex items-center justify-between">
                        <div className="flex-shrink-0">
                            <img
                                className="w-[192px] my-[20px]"
                                src="assets/images/logo/LogoWhite.png"
                                alt="Workflow"
                            />
                        </div>
                        <div className="hidden lg:block">
                            <ul className="flex space-x-2 xl:space-x-4">
                                {navigation.map((item, index) => (
                                    <li key={index}>
                                        {item.type === 'link' ? (
                                            <Link
                                                to={String(item.path)}
                                                className={`
                                    hover:text-textColor px-3 py-2 flex items-center gap-2 rounded-md text-[15px] uppercase font-bold 
                                    ${location.pathname === item.path
                                                        ? 'text-textColor'
                                                        : 'text-white'
                                                    }
                                    `}
                                            >
                                                {/* {item.icon &&  <i className={`fas fa-${item.icon}`}></i>  } */}
                                                {item.title}
                                                {String(item.type) === 'group' ? (
                                                    <i className="fas fa-chevron-down"></i>
                                                ) : (
                                                    ''
                                                )}
                                            </Link>
                                        ) : (
                                            <div className="dropdown-1">
                                                {item.path ? (
                                                    <Link
                                                        to={String(item.path)}
                                                        className={`hover:text-textColor px-3 py-2 flex items-center gap-2 rounded-md text-[15px] uppercase text-white font-bold
                                                            ${item?.children?.some(
                                                            (child) =>
                                                                location.pathname ===
                                                                child.path
                                                        )
                                                                ? 'text-textColor'
                                                                : 'text-white'
                                                            }
                                                        `}
                                                    >
                                                        {item.title}
                                                        <i className="fas fa-chevron-down"></i>
                                                    </Link>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className={`hover:text-textColor px-3 py-2 flex items-center gap-2 rounded-md text-[15px] uppercase font-bold
                                                       ${item?.children?.some(
                                                            (child) =>
                                                                location.pathname ===
                                                                child.path
                                                        )
                                                                ? 'text-textColor'
                                                                : `${item?.children?.some(
                                                                    (child) =>
                                                                        child?.children?.some(
                                                                            (
                                                                                subChild
                                                                            ) =>
                                                                                location.pathname ===
                                                                                subChild.path
                                                                        )
                                                                )
                                                                    ? 'text-textColor'
                                                                    : 'text-white'
                                                                }`
                                                            }
                                                `}
                                                    >
                                                        {item.title}
                                                        <i className="fas fa-chevron-down"></i>
                                                    </button>
                                                )}
                                                <ul className="absolute  dropdown-content-1 bg-gray-800 text-white">
                                                    {item.children?.map((child, index) => (
                                                        <li
                                                            key={index}
                                                            className="hover:text-primaryColor hover:bg-textColor overflow-hidden"
                                                        >
                                                            {child.type === 'link' ? (
                                                                <Link
                                                                    to={String(child.path)}
                                                                    className={`px-5 py-2 flex items-center gap-2 text-[13px] uppercase font-bold transition-all duration-300 hover:translate-x-2
                                                                    ${location.pathname ===
                                                                            child.path
                                                                            ? 'text-primaryColor bg-textColor'
                                                                            : 'text-white'
                                                                        }
                                                                    `}
                                                                >
                                                                    {child.title}
                                                                </Link>
                                                            ) : (
                                                                <div className="dropdown-2">
                                                                    {child.path ? (
                                                                        <Link
                                                                            to={String(child.path)}
                                                                            className={`px-5 py-2 flex items-center gap-2 text-[13px] uppercase font-bold transition-all duration-300 hover:translate-x-2
                                                                            ${location.pathname ===
                                                                                    child.path
                                                                                    ? 'text-primaryColor bg-textColor'
                                                                                    : `${child?.children?.some(
                                                                                        (
                                                                                            subChild
                                                                                        ) =>
                                                                                            location.pathname ===
                                                                                            subChild.path
                                                                                    )
                                                                                        ? 'text-primaryColor bg-textColor'
                                                                                        : 'text-white'
                                                                                    }`
                                                                                }
                                                                            `}
                                                                        >
                                                                            {child.title}
                                                                            <i className="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="px-5 py-2 flex items-center gap-2 text-[13px] uppercase font-bold transition-all duration-300 hover:translate-x-2"
                                                                        >
                                                                            {child.title}
                                                                            <i className="fas fa-chevron-down"></i>
                                                                        </button>
                                                                    )}
                                                                    <ul className="absolute dropdown-content-2 bg-gray-800 text-white">
                                                                        {child.children?.map((subChild, index) => (
                                                                            <li
                                                                                key={index}
                                                                                className="hover:text-primaryColor hover:bg-textColor"
                                                                            >
                                                                                <Link
                                                                                    to={String(subChild.path)}
                                                                                    className={`px-5 py-2 flex items-center gap-2 text-[13px] uppercase font-bold transition-all duration-300 hover:translate-x-2
                                                                                        ${location.pathname ===
                                                                                            subChild.path
                                                                                            ? 'text-primaryColor bg-textColor'
                                                                                            : 'text-white'
                                                                                        }
                                                                                    `}
                                                                                >
                                                                                    {subChild.title}
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                ))}
                                <Link to="/register">
                                    <li className="whitespace-nowrap text-primaryColor px-4 py-2 rounded-md text-[15px] uppercase font-medium hover:bg-secondaryColor bg-white cursor-pointer">
                                        APPLY NOW
                                    </li>
                                </Link>
                                {/* {localStorage} */}
                                {/* <i className="fa-solid fa-grid-horizontal"></i> */}
                                {localStorage.getItem("accessToken") &&
                                    <Tooltip title="Go to Admin Dashboard" arrow sx={{ zIndex: 999999 }}>
                                        <Link to="/dashboard">
                                            <li className="whitespace-nowrap text-primaryColor px-4 py-2 rounded-md text-[15px] uppercase font-medium hover:bg-secondaryColor bg-white cursor-pointer">
                                                <i className="fa-solid fa-grid-horizontal"></i>
                                            </li>
                                        </Link>
                                    </Tooltip>
                                }
                            </ul>
                        </div>
                        {open ? null : (
                            <div className="-mr-2 flex lg:hidden items-center gap-2 z-[99]">
                                {localStorage.getItem("accessToken") &&
                                    <Tooltip title="Go to Admin Dashboard" arrow sx={{ zIndex: 999999 }}>
                                        <Link to="/dashboard">
                                            <div className="whitespace-nowrap  text-primaryColor px-2 py-1 rounded-md text-[14px] uppercase font-medium hover:bg-secondaryColor bg-white cursor-pointer">
                                                <i className="fa fa-grid"></i>
                                            </div>
                                        </Link>
                                    </Tooltip>
                                }
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center p-1 rounded-md hover:text-primaryColor text-textColor hover:bg-textColor bg-primaryColor focus:outline-none"
                                    aria-controls="mobile-menu"
                                    aria-expanded="false"
                                    onClick={handleOpen}
                                >
                                    <MenuIcon fontSize="medium" />
                                </button>
                            </div>

                        )}
                    </div>
                </div>
            </nav>
            <nav className={`fixed w-full ${open ? 'h-full z-[999]' : 'z-[99]'}`}>
                <div
                    className={`${open
                        ? 'absolute block z-[9999] top-0 bottom-0 right-0 left-0 bg-[#0000008a] transition-opacity'
                        : 'hidden'
                        } lg:hidden`}
                    id="mobile-menu-bg"
                    onClick={handleOpen}
                >
                    <div className="-mr-2 lg:hidden absolute top-0 left-0 p-2">
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md hover:text-primaryColor text-textColor hover:bg-textColor bg-primaryColor hover:rotate-180 hover:duration-300 hover:transition-all"
                            onClick={handleOpen}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>
                <div
                    className={`${open
                        ? 'absolute block z-[9999] top-0 bottom-0 right-0 max-w-[75%] w-[400px] bg-primaryColor mobile-menu slide-in-right'
                        : 'hidden slide-out-right'
                        } lg:hidden`}
                    id="mobile-menu"
                >
                    <div className="w-full h-full float-end bg-menuBarImage bg-cover bg-center bg-no-repeat">
                        <Link className="flex items-center justify-center" to="/">
                            <img
                                className="w-[220px] py-10"
                                src="assets/images/logo/LogoWhite.png"
                                alt="Workflow"
                            />
                        </Link>
                        <div className="pt-2">
                            {navigation.map((item, index) => (
                                <div
                                    key={index}
                                    className={`border-[#9999999c] ${index === 0 ? 'border-y-[1.5px]' : 'border-b-[1.5px]'
                                        } `}
                                >
                                    {item.type === 'link' ? (
                                        <div className='hover:bg-textColor'>
                                            <Link
                                                onClick={handleOpen}
                                                to={String(item.path)}
                                                className={`
                                    hover:text-primaryColor px-3 py-2 flex items-center gap-2 text-[14px] uppercase font-bold transition-all duration-300 hover:translate-x-2
                                    ${location.pathname === item.path
                                                        ? 'text-primaryColor bg-textColor'
                                                        : 'text-white'
                                                    }
                                    `}
                                            >
                                                {/* {item.icon &&  <i className={`fas fa-${item.icon}`}></i>  } */}
                                                {item.title}
                                                {String(item.type) === 'group' ? (
                                                    <i className="fas fa-chevron-down"></i>
                                                ) : (
                                                    ''
                                                )}
                                            </Link>
                                        </div>
                                    ) : (
                                        <div>
                                            {item.path ? (
                                                <div
                                                    className={`flex items-center justify-between w-full text-[14px] uppercase  font-bold ${subItem ? 'bg-[#f6b53ce5]' : 'text-white'
                                                        }`}
                                                >
                                                    <Link
                                                        onClick={handleOpen}
                                                        to={String(item.path)}
                                                        className="pl-3 py-2 hover:text-primaryColor hover:bg-textColor text-white w-full cursor-pointer"
                                                    >
                                                        {item.title}
                                                    </Link>
                                                    <button
                                                        type="button"
                                                        onClick={handleSubItem}
                                                        className="px-4 py-2 border-x-[1.5px] border-[#9999999c] text-white hover:text-primaryColor hover:bg-textColor cursor-pointer"
                                                    >
                                                        <i className="fas fa-chevron-down"></i>
                                                    </button>
                                                </div>
                                            ) : (
                                                <button
                                                    type="button"
                                                    onClick={handleSubItem}
                                                    className={`flex pl-3 py-2 items-center justify-between w-full text-[14px] hover:text-primaryColor hover:bg-textColor text-white cursor-pointer uppercase  font-bold 
                                                    ${subItem ? 'bg-[#f6b53ce5]' : 'text-white'}
                                                    ${item?.children?.some(
                                                        (child) => location.pathname === child.path)
                                                            ? 'bg-[#f6b53ce5]'
                                                            : `${item?.children?.some((child) => child?.children?.some((subChild) => location.pathname === subChild.path)) ? 'bg-[#f6b53ce5]' : 'text-white'}`
                                                        }
                                                    
                                                                                                    `}
                                                >
                                                    <span className="transition-all duration-300 hover:translate-x-2 flex w-full ">
                                                        {item.title}
                                                    </span>
                                                    <div className="px-4">
                                                        <i className="fas fa-chevron-down"></i>
                                                    </div>
                                                </button>
                                            )}
                                            {subItem && (
                                                <ul>
                                                    {item.children?.map((child, index) => (
                                                        <li
                                                            key={index}
                                                            className={`border-[#9999999c] ${index === 0
                                                                ? 'border-y-[1.5px]'
                                                                : 'border-b-[1.5px]'
                                                                }`}
                                                        >
                                                            {child.type === 'link' ? (
                                                                <div className='hover:bg-textColor' onClick={handleOpen}>
                                                                    <Link
                                                                        to={String(child.path)}
                                                                        className={`px-5 py-2 flex items-center gap-2 text-[14px] uppercase font-bold transition-all duration-300 hover:translate-x-2 hover:text-primaryColor
                                                                    ${location.pathname === child.path
                                                                                ? 'text-primaryColor bg-textColor'
                                                                                : 'text-white'
                                                                            }
                                                                        `}
                                                                    >
                                                                        {child.title}
                                                                    </Link>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {child.path ? (
                                                                        <div
                                                                            className={`flex items-center justify-between w-full text-[14px] uppercase  font-bold ${subItem2
                                                                                ? 'bg-[#f6b53cb6]'
                                                                                : 'text-white'
                                                                                } `}
                                                                        >
                                                                            <Link
                                                                                onClick={handleOpen}
                                                                                to={String(child.path)}
                                                                                className="hover:text-primaryColor hover:bg-textColor text-white w-full cursor-pointer"
                                                                            >
                                                                                <span className="pl-5 py-2 transition-all duration-300 hover:translate-x-2 flex w-full ">
                                                                                    {child.title}
                                                                                </span>
                                                                            </Link>
                                                                            <button
                                                                                type="button"
                                                                                onClick={handleSubItem2}
                                                                                className="px-4 py-2 border-x-[1.5px] border-[#999] text-white hover:text-primaryColor cursor-pointer hover:bg-textColor"
                                                                            >
                                                                                <i className="fas fa-chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            onClick={handleSubItem2}
                                                                            className={`flex pl-5 py-2 items-center justify-between w-full text-[14px] hover:text-primaryColor hover:bg-textColor text-white cursor-pointer uppercase  font-bold ${subItem2
                                                                                ? 'bg-[#f6b53cb6]'
                                                                                : 'text-white'
                                                                                }`}
                                                                        >
                                                                            <span className="transition-all duration-300 hover:translate-x-2 flex w-full ">
                                                                                {child.title}
                                                                            </span>
                                                                            <div className="px-4">
                                                                                <i className="fas fa-chevron-down"></i>
                                                                            </div>
                                                                        </button>
                                                                    )}
                                                                    {subItem2 && (
                                                                        <ul className=" text-white">
                                                                            {child.children?.map(
                                                                                (subChild, index) => (
                                                                                    <li
                                                                                        key={index}
                                                                                        className="hover:bg-[#f6b53c71]"
                                                                                        onClick={handleOpen}
                                                                                    >
                                                                                        <Link
                                                                                            to={String(subChild.path)}
                                                                                            className={`px-8 py-2 flex items-center gap-2 text-[14px] uppercase font-bold transition-all duration-300 hover:translate-x-2 hover:text-primaryColor 
                                                                                        ${location.pathname ===
                                                                                                    subChild.path
                                                                                                    ? 'text-primaryColor bg-[#f6b53c71]'
                                                                                                    : 'text-white'
                                                                                                }
                                                                                            `}
                                                                                        >
                                                                                            {subChild.title}
                                                                                        </Link>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <Link to="/register" onClick={handleOpen}>
                            <div className="w-full text-primaryColor px-4 py-2 text-[14px] uppercase font-medium hover:bg-secondaryColor bg-white cursor-pointer flex items-center justify-center">
                                APPLY NOW
                            </div>
                        </Link>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavBar;
