import React from "react";
import { AdminNavBar } from "../adminNavBar";
import adminNavigationConfig from "../../routes/adminNavigationConfig";

interface AdminLayoutProps {
    children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
    return (
        <div className="bg-gray-100">
            <AdminNavBar navigation={adminNavigationConfig} />
            <div className="pt-[calc(68px+5rem)]">
                {children}
            </div>
        </div>
    );
}

export default AdminLayout;
