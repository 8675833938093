// HandleAuth.tsx
import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAppContext } from "../../contexts/AppContext";
import { AdminLayout, UserLayout } from "../components/layout";

interface HandleAuthProps {
    userType?: "SUPER_ADMIN" | "ADMIN" | "USER" | "GUEST";
}

const HandleAuth: React.FC<HandleAuthProps> = ({ userType }) => {
    const { user } = useAppContext();

    const isAuthenticated = localStorage.getItem("accessToken");

    // Redirect to login if the user is not authenticated
    if (userType !== "GUEST" && !isAuthenticated) {
        return <Navigate to={isAuthenticated ? "dashboard" : "404"} replace />;
    }

    // Redirect to unauthorized page if the user is not an admin
    if (userType === "SUPER_ADMIN" && user?.userType !== Number(process.env.REACT_APP_SUPER_ADMIN) && user?.userType !== undefined) {
        return <Navigate to="unauthorized" replace />;
    }

    if (userType === "ADMIN" && (user?.userType !== Number(process.env.REACT_APP_SUPER_ADMIN) && user?.userType !== Number(process.env.REACT_APP_ADMIN)) && user?.userType !== undefined) {
        return <Navigate to="unauthorized" replace />;
    }

    if (userType === "USER" && (user?.userType !== Number(process.env.REACT_APP_SUPER_ADMIN) && user?.userType !== Number(process.env.REACT_APP_ADMIN) && user?.userType !== Number(process.env.REACT_APP_USER)) && user?.userType !== undefined) {
        return <Navigate to="unauthorized" replace />;
    }

    // Render the appropriate layout based on the user's role
    if (userType === "SUPER_ADMIN" || userType === "ADMIN") {
        return (
            <AdminLayout>
                <Outlet />
            </AdminLayout>
        );
    } else if (userType === "USER") {
        return (
            <UserLayout>
                <Outlet />
            </UserLayout>
        );
    } else {
        return (
            <UserLayout>
                <Outlet />
            </UserLayout>
        );
    }
}

export default HandleAuth;
