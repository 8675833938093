import React from 'react'
import { FormProps } from '../../../../models/form.interface';
import InputField from '../../../components/form-fields/inputField';

interface StudyTypeProps {
    form: FormProps;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, id?: string) => void;
    error: FormProps;
}

// with all the field
const PersonalInfo: React.FC<StudyTypeProps> = ({ form, handleInputChange, error }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
            <InputField
                label='Student Number'
                type="text"
                id="studentNumber"
                name="studentNumber"
                placeholder="Student Number"
                value={form.personalInfo.studentNumber}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                error={error.personalInfo.studentNumber}
            />
            <InputField
                label='Name with initials'
                type="text"
                id="nameWithInitials"
                name="nameWithInitials"
                placeholder="Name with initials"
                value={form.personalInfo.nameWithInitials}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                error={error.personalInfo.nameWithInitials}
            />
            <div className='md:col-span-2'>
                <InputField
                    label='Full name'
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="Full name"
                    value={form.personalInfo.fullName}
                    onChange={(e) => handleInputChange(e, 'personalInfo')}
                    error={error.personalInfo.fullName}
                />
            </div>
            <InputField
                label='Date of birth'
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                placeholder="Date of birth"
                value={form.personalInfo.dateOfBirth}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                max={new Date().toISOString().split('T')[0]}
                error={error.personalInfo.dateOfBirth}
            />
            <InputField
                label='Age'
                type="text"
                id="age"
                name="age"
                placeholder="Age"
                value={form.personalInfo.age}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                disabled
                error={error.personalInfo.age}
            />
            <InputField
                label='Gender'
                type="select"
                id="gender"
                name='gender'
                placeholder='Gender'
                value={form.personalInfo.gender}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                options={[
                    { value: '', label: 'Select Gender' },
                    { value: 'MALE', label: 'Male' },
                    { value: 'FEMALE', label: 'Female' },
                    { value: 'OTHER', label: 'Other' },
                ]}
                error={error.personalInfo.gender}
            />
            <InputField
                label='NIC'
                type="text"
                id="nic"
                name='nic'
                placeholder='NIC'
                value={form.personalInfo.nic}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                error={error.personalInfo.nic}
            />
            <InputField
                label='Passport Number'
                type="text"
                id="passportNumber"
                name='passportNumber'
                placeholder='Passport Number'
                value={form.personalInfo.passportNumber}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                error={error.personalInfo.passportNumber}
            />
            <InputField
                label='Passport Expiry Date'
                type="date"
                id="passportExpiryDate"
                name='passportExpiryDate'
                placeholder='Passport Expiry Date'
                value={form.personalInfo.passportExpiryDate}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                error={error.personalInfo.passportExpiryDate}
            />
            <InputField
                label='Nationality'
                type="text"
                id="nationality"
                name='nationality'
                placeholder='Nationality'
                value={form.personalInfo.nationality}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
            />

            <InputField
                label='Marital Status'
                type="select"
                id="maritalStatus"
                name='maritalStatus'
                placeholder='Marital Status'
                value={form.personalInfo.maritalStatus}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                options={[
                    { value: '', label: 'Select Marital Status' },
                    { value: 'SINGLE', label: 'Single' },
                    { value: 'MARRIED', label: 'Married' },
                    { value: 'DIVORCED', label: 'Divorced' },
                    { value: 'WIDOWED', label: 'Widowed' },
                ]
                }
                error={error.personalInfo.maritalStatus}

            />
            <div className='md:col-span-2'>
                <InputField
                    label='Current Address'
                    type="textarea"
                    id="currentAddress"
                    name='currentAddress'
                    placeholder='Current Address'
                    value={form.personalInfo.currentAddress}
                    onChange={(e) => handleInputChange(e, 'personalInfo')}
                    error={error.personalInfo.currentAddress}
                />
            </div>
            <InputField
                label='Current Occupation'
                type="text"
                id="currentOccupation"
                name='currentOccupation'
                placeholder='Current Occupation'
                value={form.personalInfo.currentOccupation}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
                error={error.personalInfo.currentOccupation}

            />

            <InputField
                label='Name of the company'
                type="text"
                id="nameOfTheCompany"
                name='nameOfTheCompany'
                placeholder='Name of the company'
                value={form.personalInfo.nameOfTheCompany}
                onChange={(e) => handleInputChange(e, 'personalInfo')}
            />
            <div className='md:col-span-2'>
                <InputField
                    label='Job Description'
                    type="textarea"
                    id="jobDescription"
                    name='jobDescription'
                    placeholder='Job Description'
                    value={form.personalInfo.jobDescription}
                    onChange={(e) => handleInputChange(e, 'personalInfo')}
                />
            </div>


        </div>
    )
}

export default PersonalInfo
