/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as XLSX from 'xlsx';
import { FormProps } from '../../../models/form.interface';

interface DownloadExcelProps {
    data: FormProps[];
}

const DownloadExcel: React.FC<DownloadExcelProps> = ({ data }) => {
    const downloadExcel = () => {
        const jsonData = JSON.stringify(data);
        const parsedData = JSON.parse(jsonData);

        const customizedData = parsedData.map((item: FormProps) => ({
            'Applicant ID': item.applicantId,
            'Student Number': item.personalInfo.studentNumber || 'N/A',
            'Name with Initials': item.personalInfo.nameWithInitials,
            'Full Name': item.personalInfo.fullName,
            'Gender': item.personalInfo.gender,
            'Nationality': item.personalInfo.nationality,
            'Date of Birth': item.personalInfo.dateOfBirth,
            'NIC': item.personalInfo.nic,
            'Passport Number': item.personalInfo.passportNumber,
            'Passport Expiry Date': item.personalInfo.passportExpiryDate,
            'Current Address': item.personalInfo.currentAddress,
            'Current Occupation': item.personalInfo.currentOccupation,
            'Name of the Company': item.personalInfo.nameOfTheCompany,
            'Job Description': item.personalInfo.jobDescription,
            'Marital Status': item.personalInfo.maritalStatus,
            'Academic Record': formatAcademicRecord(item.academicRecord),
            'Family Info': formatFamilyInfo(item.familyInfo)
        }));

        const flattenedData = customizedData.reduce((acc: string | any[], val: any) => acc.concat(val), []);

        const ws = XLSX.utils.json_to_sheet(flattenedData, {
            skipHeader: false // Include header row
        });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const date = new Date().toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }).replace(/\//g, '-').replace(',', '');
        XLSX.writeFile(wb, `applicant-data-${date}.xlsx`);
    };

    const formatAcademicRecord = (records: FormProps['academicRecord']) => {
        return records.map(record => {
            return `${record.academicType} at ${record.nameOfTheInstitution} (${record.academicPeriod.from} - ${record.academicPeriod.to})`;
        }).join('\n');
    };

    const formatFamilyInfo = (info: FormProps['familyInfo']) => {
        return info.map(item => {
            return `${item.relation}: ${item.relativeName} (${item.relativeOccupation}) ${item.relativeDateOfBirth ? `- DOB: ${item.relativeDateOfBirth}` : ''}`;
        }).join('\n');
    };

    return (
        <button
            className="bg-primaryColor w-full text-white px-4 py-2 rounded-md whitespace-nowrap"
            onClick={downloadExcel}
        >
            <i className="fas fa-file-export"></i> Download Excel
        </button>
    );
};


export default DownloadExcel;
