import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import { CustomError } from '../../../models/global.interface';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const { user, login } = useAppContext();
    const [showPassword, setShowPassword] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            if (!email || !password) {
                setError('Please provide both email and password.');
                return;
            }

            login({ username: email, password });

        } catch (error) {
            setError(String((error as CustomError).response?.data?.message));
        }
    };

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const handleInputChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target;
        switch (name) {
            case 'email':
                setEmail(value);
                if (!emailRegex.test(value) && value.length > 0) {
                    setError('Invalid email');
                } else {
                    setError(null);
                }
                break;
            case 'password':
                setPassword(value);
                if (!passwordRegex.test(value) && value.length > 0) {
                    setError('Minimum eight characters, at least one letter, one number and one special character');
                } else {
                    setError(null);
                }
                break;
            default:
                break;
        }
    }

    // clear error messages after 5 seconds
    useEffect(() => {
        const timeout = setTimeout(() => {
            setError(null);
        }, 5000);
        return () => clearTimeout(timeout);
    }, [error]);

    useEffect(() => {
        const loggedToken = localStorage.getItem("accessToken") || null;
        if (loggedToken && user) {
            navigate(getRedirectPath(user.userType ?? 0));
        }
    }, [user, navigate]);

    const getRedirectPath = (userType: number) => {
        switch (userType) {
            case Number(process.env.REACT_APP_SUPER_ADMIN):
            case Number(process.env.REACT_APP_ADMIN):
                return "/dashboard";
            case Number(process.env.REACT_APP_USER):
            default:
                return "/";
        }
    };

    return (
        <div className='bg-primaryColor w-full'>
            <div className='container'>
                <div className='flex flex-col justify-center items-center min-h-[100vh]'>
                    <div className="logo">
                        <img
                            width="250"
                            src="assets/images/logo/LogoWhite.png"
                            alt="logo"
                        />
                    </div>
                    <form className='w-full sm:w-[400px]' onSubmit={handleLogin}>
                        <div className="flex flex-col mb-4 w-full gap-2">
                            <label htmlFor="username" className="text-white">Username</label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Enter email"
                                value={email}
                                className={`w-full rounded-md py-2 px-2 border border-gray-300`}
                                onChange={(e) => handleInputChange(e)}
                            />

                        </div>
                        <div className="flex flex-col mb-4 w-full gap-2">
                            <label htmlFor="password" className="text-white">Password</label>
                            <div className="relative w-full">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    placeholder="Enter new password"
                                    value={password}
                                    className={`w-full rounded-md py-2 px-2 border ${error ? 'border-red-500' : 'border-gray-300'}`}
                                    onChange={(e) => handleInputChange(e)}
                                />
                                <div
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="text-primaryColor text-sm absolute right-2 bottom-0 transform -translate-y-1/2 cursor-pointer"
                                >
                                    <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                </div>
                            </div>
                        </div>
                        {error && <div style={{ color: 'white' }}>{error}</div>}
                        <button type="submit" className="bg-[#aa2a30] text-white py-2 rounded-md    w-full">
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
