// animations
export const container = {
	show: {
		transition: {
			staggerChildren: 0.1
		}
	}
};

export const item = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 }
};
