import React, { useEffect } from "react";
import { UserProps } from "../../../../models/user.interface";
import { GET } from "../../../auth/AxiosHelper";

const UserMaster: React.FC = () => {
    const [users, setUsers] = React.useState<UserProps[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    const fetchUsers = async () => {
        try {
            const res = await GET("/user/get-all-by-is-admin");
            if (res.status === 200) {
                setUsers(res.data.data);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="min-h-screen container py-10 space-y-5">
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-[24px] text-primaryColor">User Master</h1>
                    <span className="text-gray-500">Manage Users</span>
                </div>
            </div>
            <div className="block overflow-auto w-full bg-gray-300">
                <table className="border-collapse border border-slate-500 w-full">
                    <thead className=''>
                        <tr>
                            <th className='border border-slate-600 px-4 py-2'>User ID</th>
                            <th className='border border-slate-600 px-4 py-2'>Name</th>
                            <th className='border border-slate-600 px-4 py-2'>Email</th>
                            <th className='border border-slate-600 px-4 py-2'>Status</th>
                            <th className='border border-slate-600 px-4 py-2'>Type</th>
                            {/* <th className='border border-slate-600 px-4 py-2'>Action</th> */}

                        </tr>
                    </thead>
                    <tbody>
                        {loading ?
                            <tr>
                                <td className='border border-slate-600 px-4 py-2' colSpan={4}>Loading...</td>
                            </tr>
                            : users.length > 0 ? users.map((item, index) => (
                                <tr key={index}>
                                    <td className='border border-slate-600 px-4 py-2'>{item.userId}</td>
                                    <td className='border border-slate-600 px-4 py-2'>{item.name}</td>
                                    <td className='border border-slate-600 px-4 py-2'>{item.email}</td>
                                    <td className='border border-slate-600 px-4 py-2'>{item.isActive ? 'Active' : 'Inactive'}</td>
                                    <td className='border border-slate-600 px-4 py-2'>{item.userType === 99 ? 'Admin' : 'User'}</td>
                                    {/* <td className='border border-slate-600 px-4 py-2'>
                                        <button className='bg-primaryColor text-white px-2 py-1 rounded-lg'>View</button>
                                    </td> */}

                                </tr>
                            ))
                                :
                                <tr>
                                    <td className='border border-slate-600 px-4 py-2' colSpan={4}>No User Found</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default UserMaster;