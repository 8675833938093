import React from "react";
import { useNavigate } from "react-router-dom";

const data = [
    {
        title: "Language Courses",
        description: "For Students",
        icon: "assets/images/icons/icon-2.png",
        bgImage: "bg-bgServices-1",
        scrollSection: "for-students"
    },
    // {
    //     title: "Language Courses",
    //     description: "For Business Purposes",
    //     icon: "assets/images/icons/icon-1.png",
    //     bgImage: "bg-bgServices-2",
    //     scrollSection: "for-business"
    // },
    // {
    //     title: "JLPT",
    //     description: "Past Paper Discussions",
    //     icon: "assets/images/icons/icon-3.png",
    //     bgImage: "bg-bgServices-3",
    //     scrollSection: "past-paper"
    // }
    {
        title: "Masters",
        description: "English Medium Masters",
        icon: "assets/images/icons/icon-3.png",
        bgImage: "bg-bgServices-3",
        scrollSection: "masters"
    }

]
const ServiceSection: React.FC = () => {
    const navigate = useNavigate();

    return (
        <section className="bg-bgService bg-cover bg-center bg-no-repeat py-20 bg-white">
            <div className="container">
                <div className="">
                    <div className="border-l-[8px] border-primaryColor uppercase">
                        <p className="text-[20px] md:text-[30px] font-semibold text-[#999999] pl-2">What We Do?</p>
                        <p className=" text-primaryColor text-[50px] sm:text-[80px] md:text-[112px] md:leading-[100px]" >SERVICES</p>
                    </div>

                    <div className="flex flex-wrap">
                        <div className="w-full flex items-center uppercase flex-col md:flex-row rounded-md overflow-hidden mt-5">
                            <div className="bg-primaryColor w-full h-full flex items-center justify-center">
                                <div className="text-xl font-semibold text-white flex items-center gap-4 justify-center py-4 px-10 text-center">
                                    <div className="bg-white h-[4px] w-[30px] rounded-md" />
                                    <div className="text-[26px] md:text-[36px]">
                                        Japanese Language Classes
                                    </div>
                                    <div className="bg-white h-[4px] w-[30px] rounded-md" />
                                </div>
                            </div>
                            <div className="bg-[#525967] h-full w-full md:w-[25%] flex items-center justify-center py-6">
                                <div
                                    className="whitespace-nowrap text-primaryColor px-4 py-2 rounded-md text-[18px] uppercase font-medium hover:bg-textColor bg-white cursor-pointer"
                                    onClick={() =>
                                        navigate(`/japanese-language-classes`)
                                    }
                                >
                                    View More
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-14 md:gap-6 px-4 uppercase">
                            {data.length > 0 && data.map((item, index) => (
                                <div
                                    key={index}
                                    className={`group w-full h-[400px] bg-cover bg-center bg-no-repeat ${item.bgImage}`}
                                    onClick={() =>
                                        navigate(`/japanese-language-classes`, {
                                            state: { scrollSection: item.scrollSection },
                                        })
                                    }

                                >
                                    <div className="bg-[#0000007c] h-full group-hover:bg-[#000000a8] flex flex-col items-center justify-center relative cursor-pointer space-y-4">
                                        <div className="text-3xl text-center font-bold text-white group-hover:text-textColor  group-hover:-translate-y-4 transition-all duration-300">   {item.title}</div>
                                        <div className="bg-white h-[4px] w-[30px] rounded-md group-hover:w-[100px] group-hover:bg-textColor group-hover:-translate-y-4 transition-all duration-300" />
                                        <div className="text-2xl font-thin text-center text-white group-hover:-translate-y-4 transition-all duration-300">{item.description}</div>
                                        <div className="bg-primaryColor p-4 absolute -bottom-10 group-hover:p-6  transition-all duration-300">
                                            <img
                                                className="w-[50px]"
                                                src={item.icon}
                                                alt="Workflow"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;
