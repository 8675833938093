import React from 'react'
import { FormProps } from '../../../../models/form.interface';

interface StudyTypeProps {
    form: FormProps;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, id?: string) => void;
}

// master Language SchoolRounded, japanees laguage School, radio button
const StudyType: React.FC<StudyTypeProps> = ({ form, handleInputChange }) => {
    return (
        <div>
            {/* <InputField
                type="time"
                id="studyType"
                name="studyType"
                placeholder="Study Type"
            /> */}

            <div className="flex flex-col w-full gap-2">
                <label htmlFor="studyType" className='text-primaryColor font-normal'>Study Type</label>
                <div className="flex gap-2 items-center">
                    <input
                        type="radio"
                        id="studyType1"
                        name="studyType"
                        value="MASTER_LANGUAGE_SCHOOL"
                        checked={form.studyType === 'MASTER_LANGUAGE_SCHOOL'}
                        onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="studyType1">Master Language School</label>
                </div>
                <div className="flex gap-2 items-center">
                    <input
                        type="radio"
                        id="studyType2"
                        name="studyType"
                        value="JAPANESE_LANGUAGE_SCHOOL"
                        checked={form.studyType === 'JAPANESE_LANGUAGE_SCHOOL'}
                        onChange={(e) => handleInputChange(e)}
                    />
                    <label htmlFor="studyType2">Japanese Language School</label>
                </div>

            </div>
        </div>
    )
}

export default StudyType
