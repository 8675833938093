import React from 'react'
import { GalleryProps } from '../../../../../models/global.interface';

interface oldGalleryProps {
    loading: boolean;
    galleryData: GalleryProps[];
}
const OldGallery: React.FC<oldGalleryProps> = ({ loading, galleryData }) => {

    return (
        <div>
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-[24px] text-primaryColor">Gallery</h1>
                    <span className="text-gray-500">Added Gallery</span>
                </div>
            </div>
            {loading ?
                <div className='w-full h-[300px] bg-gray-200 animate-pulse rounded-lg' />
                : galleryData.length > 0 ? galleryData.map((item, index) => (
                    <div key={index} className='w-full'>
                        <div className='w-full space-y-6'>
                            <div>
                                <h2 className='text-[24px] font-light text-primaryColor capitalize'>{item.title}</h2>
                                <div className="relative">
                                    <div className="bg-textColor h-[4px] w-[80px] absolute top-0 left-0 z-[2] rounded-md" />
                                    <div className="bg-primaryColor h-[1.3px] w-full absolute top-[1.5px] left-0 rounded-md" />
                                </div>
                            </div>
                            <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-5'>
                                {item.images.map((image, index) => (
                                    <div key={index} className='w-full h-[200px] lg:h-[300px] rounded-lg overflow-hidden'>
                                        <img src={image} alt={item.title} className='w-full h-full object-cover' />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
                    :
                    <div>
                        <h2 className='text-[24px] font-light text-primaryColor capitalize'>No Gallery Found</h2>
                    </div>
            }
        </div>
    )
}

export default OldGallery
