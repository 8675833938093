import React, { useEffect } from "react";
import { CommonHeader } from "../../../../components/CommonHeader";
import { useLocation } from "react-router-dom";

const data = [
    {
        title: "EMPLOYMENT CONSULTATION",
        icon: "assets/images/icons/icon-5.png",
        scrollSection: "employment-consultation"
    },
    {
        title: "STUDENT ENROLLMENT CONSULTATION",
        icon: "assets/images/icons/icon-4.png",
        scrollSection: "student-enrollment"
    }

]
const Consultation: React.FC = () => {
    const location = useLocation();

    const handleScroll = (id: string) => {
        // const element = document.getElementById(id);
        // if (element) {
        //     element.scrollIntoView({ behavior: "smooth" });
        // }

        // with padding top of 48px
        const element = document.getElementById(id);
        const offset = 148;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element?.getBoundingClientRect().top;
        const elementPosition = elementRect ? elementRect - bodyRect : 0;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
    useEffect(() => {
        if (!location?.state) {
            scrollTo(0, 0);
        } else {
            const id = location?.state?.scrollSection;
            handleScroll(id);
        }
    }, []);
    return (
        <div className="min-h-[calc(100vh-48px-3.85rem)]" >
            <CommonHeader title='CONSULTATION' bgImage='bg-Service-2' />
            <div className="bg-white">
                <div className="container py-10 flex justify-center flex-col items-center space-y-4">
                    <div className='parallelogram' />
                    <p className="text-[20px] font-railway">IF YOU HAVE ANY CONFUSION</p>
                    <div className="flex items-center md:gap-3 text-[28px] md:text-[40px] font-bold flex-wrap">
                        <span className="text-[#525967]"> WE CAN HELP </span>
                        <span className="text-primaryColor"> YOU </span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-14 md:gap-6 px-4 uppercase py-10">
                        {data.length > 0 && data.map((item, index) => (
                            <div key={index}>
                                <div className="group flex flex-col items-center justify-center relative cursor-pointer space-y-4" onClick={() => handleScroll(item.scrollSection)}>
                                    <div className="bg-primaryColor p-4  transition-all duration-300 flip-card"
                                        onClick={() => handleScroll(item.scrollSection)}

                                    >
                                        <img
                                            className="w-[60px]"
                                            src={item.icon}
                                            alt="Workflow"
                                        />
                                    </div>
                                    <h2 className="text-2xl font-bold text-center">{item.title}</h2>
                                    <div className="bg-primaryColor w-20 h-1 mx-auto transition-all duration-300 group-hover:w-36" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                <div className='flex flex-col justify-center'>
                    <div className='bg-cover bg-top h-full fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-Service-7' />
                    <div className="container z-10">
                        <div className="px-5 md:px-20 pt-24 pb-16 text-white text-center">
                            <div className="text-[20px] md:text-[28px]"> WE CAN HELP YOU WITH ANY CONFUSION OR DOUBTS</div>
                        </div>
                    </div>
                    <div className="w-full bg-gray-100  mt-36 pb-20">
                        <div className="container z-10  -mt-36">
                            <div className="shadow-xl">
                                <section id="student-enrollment" className="border-b-[8px] border-primaryColor">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center  justify-center text-white p-5 min-h-[300px] bg-cover bg-center bg-no-repeat bg-consultations-4">
                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                STUDENT ENROLLMENT CONSULTATION
                                            </span>
                                        </div>
                                        <div className="bg-white flex flex-col items-center justify-center text-black space-y-10 p-5">
                                            <span className="text-justify font-bold text-[1rem] leading-7">
                                                Students who are hopping to join our classes should contact us. There after we will analyze each student individually, then we will come out with suggestions and proceed further.
                                            </span>
                                        </div>
                                    </div>
                                </section>
                                <section id="employment-consultation">
                                    <div className="w-full bg-primaryColor grid grid-cols-1 md:grid-cols-2 text-center">
                                        <div className="flex flex-col items-center  justify-center text-white p-5 min-h-[300px] bg-cover bg-center bg-no-repeat bg-consultations-5">
                                            <span className="text-[28px] md:text-[40px] font-bold">
                                                EMPLOYMENT CONSULTATION
                                            </span>
                                        </div>
                                        <div className="bg-white flex flex-col items-center justify-center text-black space-y-4 p-5">
                                            <span className="text-center font-bold text-[1rem]">
                                                Do you like to work in Japan?

                                            </span>
                                            <span className="text-center text-[1rem]">
                                                We can provide professional guidance through your path.
                                            </span>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Consultation;
