import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { container, item } from '../../Constant';
import './register.css'
import Form from './widgets/form';

const Register: React.FC = () => {
    useEffect(() => {
        scrollTo(0, 0);
    }, []);


    return (
        <div className="min-h-[calc(100vh-48px-3.85rem)]" >
            {/* <CommonHeader title='Register' bgImage='bg-bgContact-us' /> */}
            <motion.div variants={container} initial="hidden" animate="show" className='container py-5 md:py-0'>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.6,
                    }}
                    variants={item}
                    className='pt-3 sm:pt-10 space-y-6'
                >
                    <div className='parallelogram' />
                    <div className='text-[36px] font-semibold text-gray-600 uppercase wrapper'>
                        <div className='static-text'>Register</div>
                        <ul className='dynamic-txt'>
                            <li><span>Now</span></li>
                        </ul>
                    </div>
                    <p className='text-gray-600'>Register to get access to our services</p>
                </motion.div>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.8,
                    }}
                    variants={item}
                    className=' pt-10'
                >
                    <Form />
                </motion.div>

            </motion.div>
        </div>
    );
};

export default Register;
