import React from "react";
import { useNavigate } from "react-router-dom";

const OverVision: React.FC = () => {
    const navigate = useNavigate();

    return (
        <section className='flex flex-col justify-center'>
            <div className='bg-cover bg-center h-full fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-bgImage-1' />
            <div className="container z-10">
                <div className="px-5 md:px-20 pt-10">
                    <div className="text-[36px] md:text-[68px] text-white font-semibold text-right tracking-widest">
                        OUR VISION
                    </div>
                    <div className="text-[14px] md:text-[16px] text-white md:text-right space-y-2 md:tracking-wide">
                        <p> TO BECOME ONE OF THE LEADING JAPANESE</p>
                        <p>TEACHING INSTITUTES IN JAPAN BY</p>
                        <p> SETTING STANDARDS OF EXCELLENCE IN</p>
                        <p> INNOVATIVE CURRICULUM DESIGN, TEACHING,</p>
                        <p> PROFESSIONAL DEVELOPMENT,</p>
                        <p>AND CROSS-CULTURAL UNDERSTANDING.</p>
                    </div>
                </div>
                <div className="px-5">

                    <div className="w-full bg-primaryColor mt-10 grid grid-cols-1 md:grid-cols-3 text-center">
                        <div className="h-[280px] flex flex-col items-center justify-center text-[28px] md:text-[40px] text-white font-bold">
                            OUR LOCATION
                        </div>
                        <div className="bg-white h-[280px] flex flex-col items-center justify-center text-primaryColor">
                            <span className="text-[48px] md:text-[68px]">2+</span>
                            <span className="text-[20px] md:text-[20px]">LOCATIONS</span>
                        </div>
                        <div className="h-[280px] flex flex-col items-center justify-center text-[28px] md:text-[40px] text-white font-bold hover:text-textColor cursor-pointer">
                            <div
                                onClick={() =>
                                    navigate(`/contact`)
                                }
                            >
                                VIEW
                                <i className={`fas fa-chevron-right text-[36px]`}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-gray-100 -mt-36">
                <div className="container mt-44 pb-10">
                    <div className="px-4 md:px-20 bg-white rounded-md shadow-xl">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 py-10">
                            <div className="">
                                <p className="text-[20px] md:text-[28px] text-[#525967] font-semibold text-center md:text-left leading-3">
                                    OUR
                                </p>
                                <p className="text-[28px] md:text-[48px] text-[#525967] font-semibold text-center md:text-left">
                                    MISSION
                                </p>
                                <div className="h-[4px] w-full bg-primaryColor mb-5" />
                                <p className="text-center md:text-left">
                                    EMPOWER INTERNATIONAL STUDENTS TO BECOME OUTSTANDING PARTICIPANTS IN THE SCHOOL AND UNIVERSITY COMMUNITY THROUGH EXCELLENT INSTRUCTION IN LANGUAGE, CULTURAL AWARENESS AND STUDY SKILLS
                                </p>
                            </div>
                            <div className="bg-white flex flex-col items-center justify-center text-[#000000] space-y-4">
                                <div className="flex items-center gap-3">
                                    <i className="fa fa-angle-double-right text-3xl text-primaryColor hover:ani" aria-hidden="true"></i>
                                    <p className="hover:text-primaryColor cursor-pointer">ASSIST INTERNATIONAL STUDENTS WITH THEIR ACADEMIC AND NON-ACADEMIC NEEDS</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <i className="fa fa-angle-double-right text-3xl text-primaryColor" aria-hidden="true"></i>
                                    <p className="hover:text-primaryColor cursor-pointer">PROVIDE QUALITY PROGRAMS FOR LEARNERS WITH SPECIALIZED JAPANESE NEEDS AND GOALS</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <i className="fa fa-angle-double-right text-3xl text-primaryColor" aria-hidden="true"></i>
                                    <p className="hover:text-primaryColor cursor-pointer">DEVELOP AND CONDUCT HIGH QUALITY JAPANESE LANGUAGE PROFICIENCY ASSESSMENT</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <i className="fa fa-angle-double-right text-3xl text-primaryColor" aria-hidden="true"></i>
                                    <p className="hover:text-primaryColor cursor-pointer">CONTRIBUTE TO THE ADVANCEMENT OF LANGUAGE SKILLS AND PROVIDE LEADERSHIP FOR THE JLPT PROFESSION</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default OverVision;
