import React from 'react';
import { FormProps } from '../../../../models/form.interface';
import InputField from '../../../components/form-fields/inputField';

interface StudyTypeProps {
    setForm: React.Dispatch<React.SetStateAction<FormProps>>;
    form: FormProps;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, id?: string) => void;
    error: FormProps;
}

const AcademicRecord: React.FC<StudyTypeProps> = ({ form, handleInputChange, setForm, error }) => {

    const addAcademicRecord = () => {
        setForm(prevForm => ({
            ...prevForm,
            academicRecord: [
                ...prevForm.academicRecord,
                {
                    academicId: String(form.academicRecord).length === 0 ? '1' : String(form.academicRecord.length + 1),
                    academicType: '',
                    nameOfTheInstitution: '',
                    academicPeriod: {
                        from: '',
                        to: '',
                    },
                },
            ],
        }));
    };

    const deleteAcademicRecord = (index: number) => {
        // setForm 
        setForm(prevForm => ({
            ...prevForm,
            academicRecord: [
                ...prevForm.academicRecord.slice(0, index),
                ...prevForm.academicRecord.slice(index + 1),
            ],
        }));

    };

    return (
        <div className='space-y-5'>
            {form.academicRecord.map((record, index) => (
                <div key={index} className='grid grid-cols-1 md:grid-cols-2 gap-5 w-full'>
                    <div className="flex flex-col w-full gap-2 col-span-2 md:col-span-1">
                        <label htmlFor={`academicType${index}`} className="text-primaryColor font-normal flex flex-col md:flex-row">
                            <span>Academic Type</span>
                            <span className='text-[10px]'>ex: Degree, Diploma, School, Short Course, etc.</span>
                        </label>
                        <InputField
                            // label='Academic Type'
                            type='text'
                            id={`academicType${index}`}
                            name={`academicType${index}`}
                            placeholder='Academic Type'
                            value={record.academicType}
                            onChange={(e) => handleInputChange(e, `academicRecord.${index}.academicType`)}
                            error={error.academicRecord && error.academicRecord[index] && error.academicRecord[index].academicType}
                        />
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                        <InputField
                            label='Name of the Institution'
                            type='text'
                            id={`nameOfTheInstitution${index}`}
                            name={`nameOfTheInstitution${index}`}
                            placeholder='Name of the Institution'
                            value={record.nameOfTheInstitution}
                            onChange={(e) => handleInputChange(e, `academicRecord.${index}.nameOfTheInstitution`)}
                            error={error.academicRecord && error.academicRecord[index] && error.academicRecord[index].nameOfTheInstitution}
                        />
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                        <InputField
                            label='From'
                            type='date'
                            id={`from${index}`}
                            name={`from${index}`}
                            placeholder='From'
                            value={record.academicPeriod.from}
                            onChange={(e) => handleInputChange(e, `academicRecord.${index}.academicPeriod.from`)}
                        // error={error.academicRecord && error.academicRecord[index] && error.academicRecord[index].academicPeriod.from}
                        />
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                        <InputField
                            label='To'
                            type='date'
                            id={`to${index}`}
                            name={`to${index}`}
                            placeholder='To'
                            value={record.academicPeriod.to}
                            onChange={(e) => handleInputChange(e, `academicRecord.${index}.academicPeriod.to`)}
                        // error={error.academicRecord && error.academicRecord[index] && error.academicRecord[index].academicPeriod.to}
                        />
                    </div>
                    {index !== 0 &&
                        <button
                            className='md:col-span-2 w-full px-4 py-2 bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out'
                            onClick={() => deleteAcademicRecord(index)}
                        >
                            Remove
                        </button>
                    }
                    <div className='h-0.5 bg-gray-300 w-full col-span-2' />
                </div>
            ))}
            <button
                className='px-4 py-2 w-full bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out'
                onClick={addAcademicRecord}
            >
                Add New Academic Record
            </button>
        </div>
    );
};

export default AcademicRecord;
