import React from "react";
import { motion } from 'framer-motion';
import { container, item } from '../../../Constant';

const HeroSection: React.FC = () => {
    return (
        <section className="min-h-[calc(100vh-48px-3.85rem)] bg-bgHero bg-cover bg-center bg-no-repeat flex items-center justify-center" >
            <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="flex items-center justify-evenly w-full flex-col md:flex-row md:gap-0 md:items-center pt-[calc(48px+1rem)] md:pt-0 pb-10 md:pb-0"
            >
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.7,
                        ease: 'easeInOut',
                        delay: 0.2,
                    }}
                    variants={item}
                    className="flex items-center justify-center"
                >
                    <img
                        src="assets/images/logo/logo01.png"
                        alt=""
                        className="phone-1 w-[250px] sm:w-[280px]  md:w-[300px] lg:w-[60%]"
                        data-aos="fade-right"
                    />

                </motion.div>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.9,
                        ease: 'easeInOut',
                        delay: 0.5,
                    }}
                    variants={item}
                >
                    <div
                        className="flex flex-col gap-5 text-right border-y-[1.4rem] border-primaryColor py-10 px-4 uppercase text-3xl md:text-5xl font-[800] -tracking-tighter text-white"
                        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                    >
                        <div className="text-shadow whitespace-nowrap">Your Road</div>
                        <div className="text-shadow whitespace-nowrap">To Success</div>
                        <div className="text-shadow whitespace-nowrap">We Will Pave</div>
                        <div className="text-shadow whitespace-nowrap">Your Path</div>
                    </div>
                </motion.div>

            </motion.div>
        </section>
    );
}

export default HeroSection;
