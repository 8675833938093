// ContactUs.tsx
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { container, item } from '../../Constant';
import CommonHeader from '../../components/CommonHeader/CommonHeader';


const AboutUs: React.FC = () => {
    useEffect(() => {
        scrollTo(0, 0);
    }, []);

    return (
        <div className="min-h-[calc(100vh-48px-3.85rem)]" >
            <CommonHeader title='About Us' bgImage='bg-bgAbout-us' />
            <motion.div variants={container} initial="hidden" animate="show" className='container py-5 md:py-0'>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.6,
                    }}
                    variants={item}
                    className='pt-3 sm:pt-14 flex flex-col lg:flex-row justify-between gap-5 lg:gap-10'
                >
                    <div className='w-full space-y-6'>
                        <div className='parallelogram' />
                        <p className='text-[36px] font-semibold text-gray-600 uppercase'>ABOUT US</p>
                        <p className='text-gray-600'>Observing A Path For Sri Lankans To Deal Globally Which Lead To Enhance The Economy Of The Country And Move On With The Concept Of Global Village.</p>
                        <div className='grid grid-cols-2 md:grid-cols-4'>
                            <div className='text-[20px] flex items-center'>
                                <i className="far fa-check-circle text-[20px] text-[#640a21] hover:scale-110 transition-all duration-300"></i>
                                <span className='pl-2 font-bold'>Quality</span>
                            </div>
                            <div className='text-[20px] flex items-center'>
                                <i className="far fa-check-circle text-[20px] text-[#640a21] hover:scale-110 transition-all duration-300"></i>
                                <span className='pl-2 font-bold'>Diverse</span>
                            </div>
                            <div className='text-[20px] flex items-center'>
                                <i className="far fa-check-circle text-[20px] text-[#640a21] hover:scale-110 transition-all duration-300"></i>
                                <span className='pl-2 font-bold'>Dynamic</span>
                            </div>
                            <div className='text-[20px] flex items-center'>
                                <i className="far fa-check-circle text-[20px] text-[#640a21] hover:scale-110 transition-all duration-300"></i>
                                <span className='pl-2 font-bold'>Reliance</span>
                            </div>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className="flex-shrink-0">
                            <img
                                className="w-full"
                                src="assets/images/about-us/about-2.jpg"
                                alt="Workflow"
                            />
                        </div>
                    </div>
                </motion.div>
            </motion.div>
            <motion.div variants={container} initial="hidden" animate="show" >
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.8,
                    }}
                    variants={item}
                    className='my-10'
                >
                    <div>
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="20" viewBox="0 0 1600 246">
                            <path style={{ fill: 'rgba(100,10,33,0.25)' }} d="M748.733,61.328C1189.99,255.13,1619.82,194.517,1620,198.548v48.438H-14S272.585-147.8,748.733,61.328Z" />
                            <path style={{ fill: 'rgba(100,10,33,0.25)' }} d="M748,90.809c449.12,185.245,870.82,99.762,871,103.825v44.152l-1647,21.2S266.823-107.658,748,90.809Z" />
                            <path style={{ fill: '#640a21' }} d="M748,120c449.12,170.392,870.82,72.263,871,76v60.112H-28S266.823-62.554,748,120Z" />
                        </svg>
                    </div>
                    <div className='rotate-180'>
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="20" viewBox="0 0 1600 246">
                            <path style={{ fill: 'rgba(100,10,33,0.25)' }} d="M748.733,61.328C1189.99,255.13,1619.82,194.517,1620,198.548v48.438H-14S272.585-147.8,748.733,61.328Z" />
                            <path style={{ fill: 'rgba(100,10,33,0.25)' }} d="M748,90.809c449.12,185.245,870.82,99.762,871,103.825v44.152l-1647,21.2S266.823-107.658,748,90.809Z" />
                            <path style={{ fill: '#640a21' }} d="M748,120c449.12,170.392,870.82,72.263,871,76v60.112H-28S266.823-62.554,748,120Z" />
                        </svg>
                    </div>
                </motion.div>
            </motion.div>
            <motion.div variants={container} initial="hidden" animate="show" className='container py-5 md:py-0'>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.8,
                    }}
                    variants={item} className=''>
                    <div className='w-full space-y-6 mb-10'>
                        <div className='text-[20px] md:text-[36px] text-[#640a21] uppercase flex items-center gap-3'>
                            <span className='font-semibold'>MESSAGE </span > <span className='font-thin'>FROM DIRECTOR</span><span className='font-semibold'>&middot;</span>
                        </div>
                        <div className='flex flex-col lg:flex-row gap-10'>
                            <div className='flex flex-col md:flex-row gap-10 '>
                                <div className='bg-primaryColor rounded-xl overflow-hidden text-center'>
                                    <div className='rounded-2xl overflow-hidden pt-[25px]'>
                                        <img
                                            className="w-full rounded-t-2xl"
                                            src="assets/images/about-us/madusanga-pic.jpg"
                                            alt="Workflow"
                                        />
                                        <div className='py-7 px-10'>
                                            <p className='text-white font-bold text-[40px]'>
                                                Madusanga <br />Herath
                                            </p>
                                            <p className='text-textColor text-[24px]'>
                                                MANAGING DIRECTOR
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex justify-center border-y-[8px] border-primaryColor rounded-xl overflow-hidden bg-[#e6e5e5] items-center'>
                                <div className='text-center space-y-6 text-[22px] px-10 font-light font-sans py-10'>

                                    <i className="fas fa-quote-right text-[48px] text-[#640a21]"></i>
                                    <p>Company objective is to produced educationally,</p>
                                    <p>culturally and economically enriched personality with strong roots.</p>

                                    <p> We ensure that we are always in behind your back to achieve the goals.
                                    </p>
                                    <p>Message<br />From Director</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default AboutUs;
