// ContactUs.tsx
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { container, item } from '../../Constant';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import { POST } from '../../auth/AxiosHelper';
import { toast } from 'react-toastify';


const ContactUs: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    const submitHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            e.preventDefault();
            setError('');
            if (!name || !email || !message) {
                setError('All fields are required');
                toast.error('All fields are required');
            } else {
                setIsLoaded(true);
                const response = await POST('mail/send-mail', { name, email, message });
                if (response.status === 200) {
                    setName('');
                    setEmail('');
                    setMessage('');
                    setError('');
                    setSuccess('Your message has been sent successfully');
                    toast.success('Your message has been sent successfully');
                    setIsLoaded(false);
                } else {
                    setError('Something went wrong. Please try again later');
                    toast.error('Something went wrong. Please try again later');
                    setIsLoaded(false);
                }

            }
        } catch (error) {
            console.log(error);
            setError('Something went wrong. Please try again later');
            toast.error('Something went wrong. Please try again later');
            setIsLoaded(false);
        }
    };

    useEffect(() => {
        scrollTo(0, 0);
    }, []);

    // after successfull submission of the form, the success message will be shown for 5 seconds and then it will be removed
    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccess('');
        }, 5000);
        return () => clearTimeout(timer);
    }, [success]);
    return (
        <div className="min-h-[calc(100vh-48px-3.85rem)]" >
            <CommonHeader title='Contact Us' bgImage='bg-bgContact-us' />
            <motion.div variants={container} initial="hidden" animate="show" className='container py-5 md:py-0'>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.6,
                    }}
                    variants={item}
                    className='pt-3 sm:pt-10'
                >
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-0'>
                        <div className='flex gap-4 items-center'>
                            <div className='bg-primaryColor text-white hover:animate-bounce p-10 w-[72px] h-[72px] flex items-center justify-center'>
                                <i className="fas fa-phone text-[32px]"></i>
                            </div>
                            <div className='flex flex-col justify-between'>
                                <span><b className='text-primaryColor text-[24px] uppercase font-medium tracking-wide'>Phone</b> </span>
                                <div className='flex flex-col justify-between'>
                                    <a href="tel:+94707882883" className='text-gray-600 hover:underline'>
                                        +94 70 7 882 883
                                    </a>
                                    <a href="tel:+94373165444" className='text-gray-600 hover:underline'>
                                        +94 37 3 165 444
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-4 items-center'>
                            <div className='bg-primaryColor text-white hover:animate-bounce p-10 w-[72px] h-[72px] flex items-center justify-center'>
                                <i className="fa-regular fa-user-doctor-message text-[32px]"></i>
                            </div>
                            <div className='flex flex-col justify-between'>
                                <span><b className='text-primaryColor text-[24px] uppercase font-medium tracking-wide whitespace-nowrap'>Consultation</b> </span>
                                <div className='flex flex-col justify-between'>
                                    <div className='flex flex-col justify-between'>
                                        <span>NO.74, 2nd Floor, NEGOMBO ROAD,<br /> KURUNEGALA, SRI LANKA.</span>
                                        <a
                                            href="https://maps.google.com/maps?ll=7.487909,80.359612&z=20&t=m&hl=en&gl=LK&mapclient=embed&q=7%C2%B029%2716.6%22N%2080%C2%B021%2735.3%22E%207.487944%2C%2080.359806@7.4879444,80.3598056"
                                            target='_blank'
                                            className='text-gray-600 hover:underline'
                                            rel="noreferrer"
                                        >
                                            Get Directions
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-4 items-center'>
                            <div className='bg-primaryColor text-white hover:animate-bounce p-10 w-[72px] h-[72px] flex items-center justify-center'>
                                <i className="far fa-map-marker-alt text-[32px]"></i>
                            </div>
                            <div className='flex flex-col justify-between'>
                                <span><b className='text-primaryColor text-[24px] uppercase font-medium tracking-wide'>Address</b> </span>
                                <div className='flex flex-col justify-between'>
                                    <span>NO.71/2, NEGOMBO ROAD, KURUNEGALA, SRI LANKA.</span>
                                    <a
                                        href="https://maps.google.com/maps?ll=7.486776,80.358064&z=20&t=m&hl=en&gl=LK&mapclient=embed&cid=11368237964196227345"
                                        target='_blank'
                                        className='text-gray-600 hover:underline'
                                        rel="noreferrer"
                                    >
                                        Get Directions
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.8,
                    }}
                    variants={item} className='flex flex-col md:flex-row justify-between my-10 gap-5 md:gap-10'>
                    <div className='w-full space-y-6'>
                        <div className='parallelogram' />
                        <h2 className='text-[24px] font-light text-primaryColor'>GET IN TOUCH</h2>
                        <div className='flex items-center justify-between'>
                            <p className='text-[36px] font-semibold text-gray-600'>CONTACT US</p>
                            <div className='flex items-center gap-2'>
                                <a
                                    target='_blank'
                                    href='https://wa.me/+94707882883'
                                    className='bg-green-500 text-white hover:animate-bounce p-3 rounded-full w-[50px] h-[50px] flex items-center justify-center'
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-whatsapp text-[32px]"></i>
                                </a>
                                <a
                                    target='_blank'
                                    href="https://www.facebook.com/NipponLankaJapaneseLanguageSchool"
                                    className='bg-[#3b5998] text-white hover:animate-bounce p-3 rounded-full w-[50px] h-[50px] flex items-center justify-center'
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-facebook-f text-[32px]"></i>
                                </a>
                            </div>
                        </div>
                        <p className='text-gray-600'>We’re Here to help and answer any inquiries you might have. We look forward to assist you</p>
                        <iframe
                            width="100%"
                            height="340"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d494.4756203038639!2d80.358064!3d7.486776!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae33b36ee6e2a6d%3A0x9dc418326a06a911!2sNippon%20Lanka%20Japanese%20Language%20School!5e0!3m2!1sen!2slk!4v1715716165139!5m2!1sen!2slk">
                            <a href="https://www.maps.ie/population/">Population Estimator map</a>
                        </iframe>
                    </div>

                    <div className='w-full'>
                        <div className='mb-5'>Write To Us</div>

                        <form>
                            <div className="grid grid-cols-1 space-y-6">
                                <div>
                                    <label htmlFor="name" className="block text-xl uppercase font-medium text-gray-700">
                                        Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="input-field"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="email" className="block text-xl uppercase font-medium text-gray-700">
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="input-field"
                                        />
                                    </div>
                                </div>
                                <div >
                                    <label htmlFor="message" className="block text-xl uppercase font-medium text-gray-700">
                                        Message
                                    </label>
                                    <div className="mt-1">
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows={6}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="input-field"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        onClick={(e) => submitHandler(e)}
                                        className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-primaryColor border border-transparent rounded-md shadow-sm hover:bg-[#640a20d3] disabled:opacity-50 disabled:cursor-not-allowed"
                                        disabled={isLoaded}
                                    >
                                        {isLoaded ? 'Sending...' : 'Send'}
                                    </button>
                                </div>
                            </div>
                            {error && <div className='text-red-500'>{error}</div>}
                            {success && <div className='text-green-500'>{success}</div>}

                        </form>

                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default ContactUs;
