/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Footer: React.FC = () => {
    const location = useLocation();
    return (
        <footer className="bg-[#640a21cf] text-white pt-12 bg-bgFooter bg-cover bg-center bg-no-repeat">
            <div className="container md:gap-10 flex flex-col md:flex-row md:justify-between">
                <div className="w-full md:w-[30%] flex flex-col items-center md:items-start gap-5">
                    <img
                        className="w-[220px] "
                        src="assets/images/logo/FooterLogo.png"
                        alt="Workflow"
                    />
                    <p className="mb-4 capitalize">
                        We are a group of former international students, who are forming a
                        school for international students. Where we provide necessary
                        academic and non-academic requirements.
                    </p>
                </div>
                <nav className="md:w-1/3 md:mt-0 mt-4 lg:pl-20">
                    <h3 className="text-lg font-bold mb-4">Navigation Menu</h3>
                    <div className="relative pb-4">
                        <div className="bg-textColor h-[4px] w-[80px] absolute top-0 left-0 z-[2] rounded-md" />
                        <div className="bg-white h-[1.3px] w-full absolute top-[1.5px] left-0 rounded-md" />
                    </div>
                    <ul className="list-reset space-y-4 mt-2">
                        <li>
                            <Link
                                to="/"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/japanese-language-classes"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/japanese-language-classes' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Japanese Language
                                Classes
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/japanese-language-classes/full-time-course"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/japanese-language-classes/full-time-course' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Full Time Course
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/japanese-language-classes/advance-course"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/japanese-language-classes/advance-course' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Advance Course
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/consultation"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/consultation' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Consultation
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/japanese-language-classes/part-time-course"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/japanese-language-classes/part-time-course' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Part Time Course
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/masters-program"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/masters-program' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Master's Program
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/gallery"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/gallery' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Gallery
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/about' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>About Us
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                className={`hover:text-textColor flex gap-2 items-center ${location.pathname === '/contact' ? 'text-textColor' : 'text-white'}`}
                            >
                                <i className="fas fa-chevron-right"></i>Contact Us
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div className="md:w-1/3 md:mt-0 mt-8 flex flex-col gap-4">
                    <h3 className="text-lg font-bold">Contact Us</h3>
                    <div className="relative">
                        <div className="bg-textColor h-[4px] w-[80px] absolute top-0 left-0 z-[2] rounded-md" />
                        <div className="bg-white h-[1.3px] w-full absolute top-[1.5px] left-0 rounded-md" />
                    </div>

                    <ul className="list-reset space-y-2 mt-2">
                        <li className="flex items-start gap-3">
                            <i className="fas fa-phone-alt mt-[0.4rem]"></i>{' '}
                            <div className="flex flex-col pl-4">
                                <span className="text-lg font-bold"> Phone</span>
                                <a
                                    href="tel:+94707882883"
                                    className="text-white hover:text-textColor"
                                >
                                    +94 70 7882 883
                                </a>
                                <a
                                    href="tel:+94373165444"
                                    className="text-white hover:text-textColor"
                                >
                                    +94 37 3165 444
                                </a>
                            </div>
                        </li>
                    </ul>
                    <div className="bg-white h-[1.3px] w-full rounded-md" />
                    <ul className="list-reset">
                        <li className="flex items-start gap-3">
                            <i className="fas fa-envelope mt-[0.4rem]"></i>{' '}
                            <div className="flex flex-col pl-4">
                                <span className="text-lg font-bold"> Email</span>
                                <a
                                    href="mailto:info@nipponlanka.lk"
                                    className="text-white hover:text-textColor"
                                >
                                    info@nipponlanka.lk
                                </a>
                                <a
                                    href="mailto:nipponlankasri@gmail.com"
                                    className="text-white hover:text-textColor"
                                >
                                    nipponlankasri@gmail.com
                                </a>
                            </div>
                        </li>
                    </ul>

                    <div className="bg-white h-[1.3px] w-full rounded-md" />
                    <ul className="list-reset">
                        <li className="flex items-start gap-3">
                            <i className="fas fa-map-marker-alt mt-[0.4rem]"></i>{' '}
                            <div className="flex flex-col pl-5">
                                <span className="text-lg font-bold"> Address</span>
                                <a
                                    href="https://maps.google.com/maps?ll=7.486776,80.358064&z=20&t=m&hl=en&gl=LK&mapclient=embed&cid=11368237964196227345"
                                    target="_blank"
                                    className="text-white hover:text-textColor"
                                    rel="noreferrer"
                                >
                                    No.71/2, Negombo road, Kurunegala,
                                    <br /> <span className=""> Sri Lanka</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                    <div className="bg-white h-[1.3px] w-full rounded-md" />
                    <ul className="list-reset">
                        <li className="flex items-start gap-3">
                            <i className="fas fa-globe mt-[0.4rem]"></i>{' '}
                            <div className="flex flex-col pl-4">
                                <span className="text-lg font-bold"> Social Media</span>
                                <div className="flex items-center gap-4">
                                    <a
                                        href="https://www.facebook.com/NipponLankaJapaneseLanguageSchool"
                                        target="_blank"
                                        className="text-white hover:text-textColor flex items-center gap-2"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-facebook-f"></i> Facebook
                                    </a>
                                    <a
                                        href='https://wa.me/+94707882883'
                                        target="_blank"
                                        className="text-white hover:text-textColor flex items-center gap-2"
                                        rel="noreferrer"
                                    >
                                        <i className="fab fa-whatsapp text-[17px]"></i> WhatsApp
                                    </a>

                                </div>

                            </div>
                        </li>
                    </ul>
                    <div className="bg-white h-[1.3px] w-full rounded-md" />
                </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-center mt-8 bg-black py-4">
                <p className="text-center">
                    © Copyright 2024 | NIPPON LANKA | All Rights Reserved |
                </p>
                <p className="text-center pl-2">
                    Developed By
                    <a
                        href="https://pramudnadula.com/"
                        target="_blank"
                        className="pl-2 text-blue-500 hover:text-textColor"
                        rel="noreferrer"
                    >
                        Pramud Nadula
                    </a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;