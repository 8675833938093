/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useState, ReactNode, useLayoutEffect } from 'react';
import { UserProps } from '../models/user.interface';
import SplashScreen from '../global/splashScreen';
import { GET, POST } from '../app/auth/AxiosHelper';
import { CustomError } from '../models/global.interface';
import { toast } from 'react-toastify';

interface AppContextType {
    isAdmin: boolean;
    user: UserProps | null;
    login: (credentials: { username: string; password: string }) => void;
    logout: () => void;
    userTypeLocal: string;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
    children: ReactNode;
}



export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [user, setUser] = useState<UserProps | null>(null);
    const [userTypeLocal, setUserTypeLocal] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const getUserFromToken = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("accessToken");
            if (token) {
                const res = await GET(`user/me`);
                setUser(res.data.data);
                setIsAdmin(res.data.data.isAdmin);
                setLoading(false);
                if (res.data.data?.userType === Number(process.env.REACT_APP_SUPER_ADMIN)) {
                    setUserTypeLocal("SUPER_ADMIN");
                }
                else if (res.data.data?.userType === Number(process.env.REACT_APP_ADMIN)) {
                    setUserTypeLocal("ADMIN");
                }
                else if (res.data.data?.userType === Number(process.env.REACT_APP_USER)) {
                    setUserTypeLocal("USER");
                }
                else {
                    setUserTypeLocal("GUEST");
                }
            }
        } catch (err) {
            console.log((err as CustomError).response?.status, "err");
            if ((err as CustomError).response?.status === 403 || (err as CustomError).response?.status === undefined) {
                localStorage.removeItem("accessToken");
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }

    const login = async (credentials: { username: string; password: string }) => {
        try {
            setLoading(true);
            const data = {
                usernameOrEmail: credentials.username,
                password: credentials.password
            }

            const res = await POST(`user/login`, data, { includeAuthorization: false });

            localStorage.setItem("accessToken", res.data.tokens.accessToken);
            if (res.data) {
                await getUserFromToken();
                toast.success("Login Successful");
            }


        }
        catch (error) {
            console.log(error);
            setLoading(false);
            toast.error((error as CustomError).response?.data?.message);
        }
    };

    const logout = () => {
        localStorage.removeItem("accessToken");
        setUser(null);
        setIsAdmin(false);
    };


    // if accessToken is  available
    useLayoutEffect(() => {
        if (localStorage.getItem("accessToken")) {
            getUserFromToken();
        }
    }, [localStorage.getItem("accessToken")]);

    const contextValue: AppContextType = {
        isAdmin,
        user,
        login,
        logout,
        userTypeLocal,
    };

    return loading ? <SplashScreen /> : <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;

};

export const useAppContext = (): AppContextType => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
