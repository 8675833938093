// ContactUs.tsx
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { container, item } from '../../Constant';
import CommonHeader from '../../components/CommonHeader/CommonHeader';
import { GET } from '../../auth/AxiosHelper';
import { GalleryProps } from '../../../models/global.interface';


const Gallery: React.FC = () => {
    const [galleryData, setGalleryData] = React.useState<GalleryProps[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);

    const fetchGalleryData = async () => {
        try {
            const res = await GET('/gallery/all');
            if (res.status === 200) {

                // PROCESS.env.REACT_APP_API_URL + '/uploads/' + image
                const data = res.data.data.map((item: GalleryProps) => {
                    return {
                        ...item,
                        images: item.images.map((image: string) => process.env.REACT_APP_API_URL + '/' + image)
                    }
                });
                setGalleryData(data);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGalleryData();
    }, []);

    useEffect(() => {
        scrollTo(0, 0);
    }, []);

    return (
        <div className="min-h-[calc(100vh-48px-3.85rem)]" >
            <CommonHeader title='Gallery' bgImage='bg-bgContact-us' />
            <motion.div variants={container} initial="hidden" animate="show" className='container py-5 md:py-0'>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.6,
                    }}
                    variants={item}
                    className='pt-3 sm:pt-10 space-y-6'
                >
                    <div className='parallelogram' />
                    <p className='text-[36px] font-semibold text-gray-600 uppercase'>GALLERY</p>
                    <p className='text-gray-600'>Here are some of our best moments</p>
                </motion.div>
                <motion.div
                    animate={{
                        opacity: 1,
                        left: 0,
                        right: 0,
                    }}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut',
                        delay: 0.8,
                    }}
                    variants={item}
                    className='py-3 sm:py-10 flex flex-col gap-5'
                >

                    {/* {data.length > 0 && data.map((item, index) => ( */}
                    {loading ?
                        <div className='w-full h-[300px] bg-gray-200 animate-pulse rounded-lg' />
                        : galleryData.length > 0 ? galleryData.map((item, index) => (
                            <div key={index} className='w-full'>
                                <div className='w-full space-y-6'>
                                    <div>
                                        <h2 className='text-[24px] font-light text-primaryColor capitalize'>{item.title}</h2>
                                        <div className="relative">
                                            <div className="bg-textColor h-[4px] w-[80px] absolute top-0 left-0 z-[2] rounded-md" />
                                            <div className="bg-primaryColor h-[1.3px] w-full absolute top-[1.5px] left-0 rounded-md" />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-5'>
                                        {item.images.map((image, index) => (
                                            <div key={index} className='w-full h-[200px] lg:h-[300px] rounded-lg overflow-hidden'>
                                                <img src={image} alt={item.title} className='w-full h-full object-cover' />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))
                            :
                            <div>
                                <h2 className='text-[24px] font-light text-primaryColor capitalize'>No Gallery Found</h2>
                            </div>
                    }
                </motion.div>
            </motion.div>
        </div>
    );
};

export default Gallery;
