import React from "react";
import { useNavigate } from "react-router-dom";

const data = [
  {
    title: `Student <br/> Enrollment <br/> Consultation`,
    icon: "assets/images/icons/icon-5.png",
    bgImage: "bg-consultations-1",
    scrollSection: "student-enrollment"
  },
  {
    title: `Employment <br/> Consultation`,
    icon: "assets/images/icons/icon-4.png",
    bgImage: "bg-consultations-3",
    scrollSection: "employment-consultation"
  }
]
const Consultations: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 py-10 uppercase bg-white">
      <div className={`w-full h-[400px] bg-cover bg-center bg-no-repeat bg-consultations-2`}>
        <div className="h-full group-hover:bg-[#000000a8] flex flex-col items-center justify-center relative space-y-3">
          <div className="flex items-center">
            <i className={`fas fa-chevron-right text-[45px] text-textColor`}></i>
            <div className="ml-5 text-[26px] md:text-[36px] text-white font-semibold">
              Consultations
            </div>
          </div>
          <div className="text-[22px] md:text-[30px] text-white">
            we offer our clients
          </div>
          <div
            className="whitespace-nowrap  text-primaryColor px-4 py-2 rounded-md text-[18px] uppercase font-medium hover:bg-textColor bg-white cursor-pointer"
            onClick={() =>
              navigate(`/consultation`)
            }
          >
            View More
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-5 md:gap-0 px-4 md:px-0 uppercase">
        {data.length > 0 && data.map((item, index) => (
          <div key={index} className={`group w-full h-[400px] bg-cover bg-center bg-no-repeat ${item.bgImage} hover:scale-105 transition-all duration-300 z-10`}>
            <div className="bg-[#0000007c] h-full group-hover:bg-[#000000a8] flex flex-col items-center md:items-start justify-between relative space-y-4 md:px-2 lg:px-10 py-20">
              <div className="space-y-4 flex flex-col items-center md:items-start">
                <div className="bg-primaryColor p-4 w-[75px] h-[75px] flex items-center justify-center">
                  <img
                    className="w-[50px]"
                    src={item.icon}
                    alt="Workflow"
                  />
                </div>
                <div className="text-2xl text-center md:text-left font-bold text-white group-hover:text-textColor" dangerouslySetInnerHTML={{ __html: item.title }} />
              </div>
              <div
                className="whitespace-nowrap  text-primaryColor px-4 py-2 rounded-md text-[18px] uppercase font-medium hover:bg-textColor bg-white cursor-pointer"
                onClick={() =>
                  navigate(`/consultation`, {
                    state: { scrollSection: item.scrollSection },
                  })
                }
              >
                View More
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Consultations;
