import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const cardData = [
        // { id: 1, title: 'Dashboard', description: 'Dashboard', icon: 'fas fa-tachometer-alt', link: '/dashboard' },
        { id: 2, title: 'Profile', description: 'Profile', icon: 'fas fa-user', link: '/profile' },
        { id: 3, title: 'Gallery Master', description: 'Gallery Master', icon: 'fas fa-images', link: '/gallery-master' },
        { id: 4, title: 'User Master', description: 'User Master', icon: 'fas fa-users', link: '/user-master' },
        { id: 5, title: 'Application Master', description: 'Application Master', icon: 'fas fa-file-alt', link: '/application-master' },

    ]
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="min-h-screen container py-10 space-y-5">
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-[24px] text-primaryColor">Dashboard</h1>
                    <span className="text-gray-500">Manage your site</span>
                </div>
            </div>
            <div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
                    {cardData.map((item) => (
                        <div key={item.id} className="bg-white p-5 rounded-md shadow-md flex flex-col items-center justify-center space-y-5">
                            <i className={`${item.icon} text-6xl text-primaryColor`}></i>
                            <h2 className="text-[24px] text-primaryColor">{item.title}</h2>
                            <p className="text-gray-500">{item.description}</p>
                            <button
                                onClick={() => navigate(item.link)}
                                type="button"
                                className="bg-primaryColor text-white px-4 py-2 rounded-md hover:opacity-80 transition-all duration-300 ease-in-out"
                            >
                                {item.title}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default Dashboard;